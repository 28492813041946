<template>
  <component
    :is="component"
    :to
    :href
    :target
    :class="classesComp"
    :type="component === 'button' ? type : undefined"
  >
    <Transition name="fade">
      <UikitLoader
        v-if="loading"
        class="osk-button__loader"
        small
        :light="lightLoading || ((color === 'primary' || color === 'green' || color === 'grey-6') && !outline && !lightOutline)"
      />

      <span
        v-else
        class="osk-button__wrapper"
      >
        <UikitLoader
          v-if="shadowLoading"
          class="osk-button__loader"
          small
          :light="lightLoading || ((color === 'primary' || color === 'green' || color === 'grey-6') && !outline && !lightOutline)"
        />

        <span class="osk-button__content">
          <UikitIcon
            v-if="icon"
            :name="icon"
            :size="iconSizeComp"
            :color="disabled ? 'grey' : iconColorComp"
            :class="{
              'osk-button__icon': true,
              'osk-button__icon_store': isStoreButton,
            }"
          />
          <slot />
        </span>
      </span>
    </Transition>
  </component>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

import type { TIconNames } from '~/assets/icons';
import type { TColors } from '~/types/components/colors';

const props = withDefaults(defineProps<{
  type?: 'submit' | 'reset' | 'button'
  color?: 'primary' | 'secondary' | 'green' | 'none' | 'white' | 'grey-6',
  outline?: boolean,
  lightOutline?: boolean
  fullWidth?: boolean,
  size?: 'xs' | 's' | 'm' | 'l',
  iconSize?: 's' | 'm' | 'l' | 'xl',
  iconColor?: TColors,
  to?: RouteLocationRaw,
  href?: string,
  target?: string,
  icon?: TIconNames,
  loading?: boolean
  shadowLoading?: boolean
  disabled?: boolean
  round?: boolean
  lightLoading?: boolean
}>(), {
  type: 'submit',
  color: 'primary',
  size: 'm',
  iconSize: undefined,
  iconColor: undefined,
  to: undefined,
  href: undefined,
  target: undefined,
  icon: undefined,
});

const slots = useSlots();

const component = computed(() => {
  if (props.to) return defineNuxtLink({});
  if (props.href) return 'a';

  return 'button';
});

const isStoreButton = computed(() => props.icon === 'AppStoreButton' || props.icon === 'GooglePlayButton');

const classesComp = computed(() => ({
  'osk-button': true,
  [`osk-button_color-${props.color}`]: !!props.color,
  [`osk-button_size-${props.size}`]: !!props.size,
  'osk-button_outline': props.outline,
  'osk-button_light-outline': props.lightOutline,
  'osk-button_full-width': props.fullWidth,
  'osk-button_only-icon': !slots.default && props.icon && !isStoreButton.value,
  'osk-button_icon-store': !slots.default && props.icon && isStoreButton.value,
  'osk-button_loading': props.loading || props.shadowLoading,
  'osk-button_disabled': props.disabled,
  'osk-button_round': props.round,
}));

const iconSizeComp = computed(() => {
  if (props.iconSize) return props.iconSize;

  return props.size === 'm' ? 'l' : 'm';
});

const iconColorComp = computed(() => {
  if (props.iconColor) return props.iconColor;

  return props.color === 'primary' ? 'white' : 'black';
});
</script>

<style lang="scss">
@use 'sass:math';
@import "~/assets/scss/settings/index";

$button-y-padding-xs: math.div($button-height-xs - $button-line-height, 2);
$button-y-padding-s: math.div($button-height-s - $button-line-height, 2);
$button-y-padding-m: math.div($button-height-m - $button-line-height, 2);
$button-y-padding-l: math.div($button-height-l - $button-line-height, 2);

.osk-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: $radius-small;
  padding: 0 $indent-large;
  transition: opacity linear .2s, border-color linear .2s;
  outline: 0;
  font-family: $font-family-default;
  font-weight: $font-weight-semibold;
  font-size: $font-size-body;
  position: relative;
  line-height: $button-line-height;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.osk-button_color-primary {
    color: $white;
    background-color: $primary;
  }

  &.osk-button_color-secondary {
    color: $black;
    background-color: $secondary;
  }

  &.osk-button_color-green {
    color: $primary;
    background-color: $green;
  }

  &.osk-button_color-white {
    color: $primary;
    background-color: $white;
  }

  &.osk-button_color-grey-6 {
    color: $white;
    background-color: $grey-6;
  }

  &_size {
    &-xs {
      @include font-small;
      font-weight: $font-weight-semibold;
      min-height: $button-height-xs;
      padding: $button-y-padding-xs 12px;

      &.osk-button_only-icon {
        width: $button-height-xs;
      }
    }

    &-s {
      min-height: $button-height-s;
      padding-top: $button-y-padding-s;
      padding-bottom: $button-y-padding-s;

      &.osk-button_only-icon {
        width: $button-height-s;
      }

      &.osk-button_icon-store {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }

    &-m {
      min-height: $button-height-m;
      padding-top: $button-y-padding-m;
      padding-bottom: $button-y-padding-m;

      &.osk-button_only-icon {
        width: $button-height-m;
      }
    }

    &-l {
      min-height: $button-height-l;
      padding-top: $button-y-padding-l;
      padding-bottom: $button-y-padding-l;

      &.osk-button_only-icon {
        width: $button-height-l;
      }
    }
  }

  &.osk-button_outline,
  &.osk-button_light-outline {
    border: 1px solid $primary;
    color: $primary;
    background: transparent;

    &:hover {
      border-color: rgba(0, 0, 0, .8);
    }

    &:active {
      border-color: rgba(0, 0, 0, .6);
    }

    &.osk-button_color-white {
      color: $white;
      background: transparent;
      border-color: $white;;
    }
  }

  &.osk-button_light-outline {
    border-color: $grey-3;
    font-weight: $font-weight-medium;
    padding: 0 $indent-compact;
  }

  &_full-width {
    display: flex;
    width: 100%;
  }

  &__icon {
    margin-right: $indent-small;

    &_store {
      margin-right: 0;
      width: 110px;
      height: 28px;
    }
  }

  &_only-icon {
    padding: 0;

    .osk-button__icon {
      margin: 0;
    }
  }

  &_loading {
    pointer-events: none;
  }

  &__loader {
    position: absolute;

    & + .osk-button__content {
      opacity: .2;
    }
  }

  &_round {
    overflow: hidden;
    border-radius: 100px;
  }

  &_disabled {
    cursor: default;
    pointer-events: none;
    opacity: .2;
  }
}
</style>
