<template>
  <UikitDialog
    :model-value="modelValue"
    full-height
    position="right"
    content-class="osk-global-dialog osk-auth-sms-dialog"
    @update:model-value="emits('update:modelValue', $event)"
    @handle-close-outside="handleClose"
    @handle-close="handleClose"
  >
    <UikitLoaderInner
      v-if="loadingComp"
      class="osk-auth-sms-dialog__loader"
    />

    <a
      class="osk-global-dialog__phone"
      href="tel:+78007075308"
      @click="handlePhone"
    >
      <UikitIcon
        name="Phone"
        size="l"
      />
    </a>

    <UikitTransitionCollapse
      :show="showTooManyRequestsError"
      class="osk-field-caption"
    >
      <div class="osk-auth-sms-dialog__error">
        {{ $t('auth.tooManyRequests') }}
      </div>
    </UikitTransitionCollapse>

    <UikitTransitionCollapse
      :show="showError || showJwtError"
      class="osk-field-caption"
    >
      <div class="osk-auth-sms-dialog__error">
        {{ $t('auth.invalidConfirmationCode') }}
      </div>
    </UikitTransitionCollapse>

    <template v-if="phoneVerifyDialogSettings?.type === 'auth' && !auth">
      <div
        :class="{
          'osk-global-dialog__title': true,
          'osk-global-dialog__title_auth': true,
        }"
      >
        {{ $t('auth.confirmPhone') }}
      </div>

      <p class="osk-global-dialog__p">
        <template v-if="path === 'WA'">
          {{ $t('auth.confirmWhatsapp') }}
        </template>

        <template v-else>
          {{ $t('auth.confirmSMS') }}
        </template>
      </p>
    </template>

    <template v-else>
      <div class="osk-global-dialog__title">
        <template v-if="path === 'WA'">
          {{ $t('auth.enterCodeWhatsapp') }}
        </template>

        <template v-else>
          {{ $t('auth.enterCodeSMS') }}
        </template>
      </div>

      <p class="osk-global-dialog__p">
        {{ $t('auth.sentToNumber') }}
      </p>
    </template>

    <div class="osk-auth-sms-dialog__phone-text">
      {{ formattedPhone }}
    </div>

    <div class="osk-auth-sms-dialog__code">
      <div
        v-for="(n, index) in verifyCodeLength"
        :key="n"
        :class="{
          'osk-auth-sms-dialog__code-box': true,
          'osk-auth-sms-dialog__code-box_filled': !!verifyCode[n - 1],
        }"
      >
        <input
          v-model="verifyCode[n - 1]"
          maxlength="1"
          autocorrect="off"
          autocomplete="off"
          autocapitalize="off"
          spellcheck="false"
          type="tel"
          :class="{
            'osk-auth-sms-dialog__code-input': true,
            'osk-auth-sms-dialog__code-input_first': index === 0,
            'osk-auth-sms-dialog__code-input_last': index === verifyCodeLength - 1,
            'osk-auth-sms-dialog__code-input_filled': !!verifyCode[n - 1],
          }"
          :disabled="!token || tooManyRequestsVerifyError"
          @focus="setSelected"
          @input.stop="inputEvent"
          @keydown.stop="downEvent"
          @keypress.stop="pressEvent"
          @paste="pasteEvent(n - 1, $event)"
        >
      </div>
    </div>

    <div
      v-if="disabled && !tooManyRequestsVerifyError"
      class="osk-auth-sms-dialog__button osk-auth-sms-dialog__button_disabled"
    >
      {{ $t('auth.nextCodeTime', expirationCounter) }}
    </div>

    <div
      v-else-if="!isFinishAttempts && !tooManyRequestsVerifyError"
      class="osk-auth-sms-dialog__buttons"
    >
      <WidgetCloudflareTurnstile />

      <button
        v-for="(b, i) in resendButtons"
        :key="i"
        :disabled="!turnstileToken"
        :class="[
          'osk-auth-sms-dialog__button',
          {
            'osk-auth-sms-dialog__button_disabled': !turnstileToken,
          }
        ]"
        @click="b.handleClick"
      >
        {{ b.text }}
      </button>
    </div>

    <template v-else-if="!tooManyRequestsVerifyError">
      <button
        class="osk-auth-sms-dialog__button"
        @click="changePhone"
      >
        {{ $t('auth.changePhoneNumber') }}
      </button>
    </template>
  </UikitDialog>
</template>

<script lang="ts" setup>
import { AnalyticsEvents } from '~/types/analytics';

import { useApi } from '~/restAPI';
import { Verification } from '~/restAPI/Verification';
import { Account } from '~/restAPI/Account';

const props = defineProps<{
  modelValue: boolean
  formattedPhone: string
  phone: string
  token?: string | null
  loading?: boolean
  error?: string
  auth?: boolean
  register?: boolean
  operation?: 'VERIFY_PHONE_NUMBER' | 'AUTH_OR_REGISTER'
  onlyCountries?: string[]
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
  (e: 'resend', value?: 'whatsapp'): void,
  (e: 'close'): void,
  (e: 'register', jwtToken?: string): void,
}>();

const { PHONE_VERIFICATION_WHATSAPP_FIRST } = useExperimentsStore();
const {
  path,
  phoneVerifyDialogSettings,
  phoneVerificationSettings,
  phoneVerifyChapter,
} = usePhoneVerification();
const {
  account,
  setAuthSuccess,
  registerDialogData,
  authDialogChapter,
} = useAccountStore();
const { $addEvent } = useNuxtApp();

const { t } = useI18n();
const { createApiInstance } = useApi();
const verificationApi = createApiInstance(Verification);
const accountApi = createApiInstance(Account);
const { turnstileToken, isVisibleWidgetContainer } = useWidgetCloudflareTurnstile();

const verifyCodeLength = ref(4);
const verifyCode = ref(new Array(verifyCodeLength.value));
const blurOnComplete = ref(true);
const disabled = ref(true);
const expirationCounter = ref(0);
const attemptsCounter = ref(0);

const attemptsCount = computed(() => phoneVerificationSettings.value?.attemptsCount ?? 3);
const attemptTimeout = computed(() => phoneVerificationSettings.value?.attemptTimeout ?? 60);
const isFinishAttempts = computed(() => attemptsCounter.value === attemptsCount.value);
const resendButtons = computed(() => {
  const items = [
    {
      handleClick: resendSmsCode,
      text: t('auth.sendNewSMS'),
    },
    {
      handleClick: resendWhatsAppCode,
      text: t('auth.sendNewWhatsapp'),
    },
  ];

  if (PHONE_VERIFICATION_WHATSAPP_FIRST.value) {
    items.reverse();
  }

  return items;
});
const fullVerifyCode = computed(() => verifyCode.value.join(''));

const chapter = computed(() => (props.auth ? authDialogChapter.value : phoneVerifyChapter.value));

const { pause, resume } = useIntervalFn(() => {
  expirationCounter.value--;

  if (expirationCounter.value === 0) {
    expirationCounter.value = attemptTimeout.value;
    disabled.value = false;
  }
}, 1000, { immediate: false });

const {
  data: isVerified,
  execute: verifyPhoneNumber,
  error: verifyError,
  pending: verifyPhoneNumberPending,
} = useLazyAsyncData(() => verificationApi.verifyPhoneNumberUsingPost(
  {
    code: fullVerifyCode.value,
    operation: 'VERIFY_PHONE_NUMBER',
    phoneNumber: props.phone,
    token: props.token!,
  },
).then((res) => res?.data?.data), { immediate: false });

verifyPhoneNumberPending.value = false;

const showJwtError = ref(false);

const {
  data: authJwt,
  execute: verifyPhoneNumberAndGetJwt,
  error: verifyJwtError,
  pending: verifyPhoneNumberAndGetJwtPending,
} = useLazyAsyncData(() => verificationApi.verifyPhoneNumberAndGetJwtUsingPost(
  {
    code: fullVerifyCode.value,
    operation: props.operation ?? 'AUTH_OR_REGISTER',
    phoneNumber: props.phone,
    token: props.token!,
  },
).then((res) => res?.data?.data), { immediate: false });

verifyPhoneNumberAndGetJwtPending.value = false;

const {
  data: IdAndJwt,
  execute: phoneNumberAuth,
  error: phoneNumberAuthError,
  pending: phoneNumberAuthPending,
} = useLazyAsyncData(() => accountApi.phoneNumberAuthUsingPost({
  phoneNumber: props.phone,
  jwtToken: authJwt.value!,
}).then((res) => res?.data?.data), { immediate: false });

phoneNumberAuthPending.value = false;

const loadingComp = computed(() => verifyPhoneNumberPending.value
  || verifyPhoneNumberAndGetJwtPending.value
  || phoneNumberAuthPending.value
  || props.loading);

const showError = computed(() => {
  if (props.auth) {
    return false;
  }

  if ((verifyPhoneNumberPending.value || !isDefined(isVerified.value)) && !verifyError.value) return false;

  return Boolean(!isVerified.value || verifyError.value);
});

const tooManyRequestsVerifyError = computed(() =>
  (verifyError.value?.data?.message ?? '') === 'Too Many Requests'
  || (verifyJwtError.value?.data?.message ?? '') === 'Too Many Requests');

const showTooManyRequestsError = computed(() => {
  if (verifyPhoneNumberPending.value || (!props.error && !tooManyRequestsVerifyError.value)) return false;

  return props.error === 'Too Many Requests' || tooManyRequestsVerifyError.value;
});

function resendSmsCode() {
  if (!turnstileToken.value) return;

  verifyError.value = null;
  showJwtError.value = false;

  emits('resend');
  path.value = 'SMS';

  $addEvent(AnalyticsEvents.ClickEvent, {
    page: 'confirmation_code',
    chapter: chapter.value,
    action: 'confirmation_code_new',
  });
}

function resendWhatsAppCode() {
  if (!turnstileToken.value) return;

  verifyError.value = null;
  showJwtError.value = false;

  emits('resend', 'whatsapp');
  path.value = 'WA';

  $addEvent(AnalyticsEvents.ClickEvent, {
    page: 'confirmation_code',
    chapter: phoneVerifyChapter.value,
    action: 'confirmation_code_whatsapp',
  });
}

function changePhone() {
  verifyError.value = null;
  showJwtError.value = false;

  emits('update:modelValue', false);

  $addEvent(AnalyticsEvents.ClickEvent, {
    page: 'confirmation_code',
    chapter: phoneVerifyChapter.value,
    action: 'add_new_phone',
  });
}

async function verifyPhoneNumberFunc() {
  await verifyPhoneNumber();

  if (tooManyRequestsVerifyError.value) {
    resetCode();

    $addEvent(AnalyticsEvents.ClickEvent, {
      page: 'confirmation_code',
      chapter: chapter.value,
      action: 'code_not_correct',
    });
    return;
  }

  if (isVerified.value) {
    account.value.isPhoneVerificationRequired = false;

    emits('update:modelValue', false);
    emits('close');

    $addEvent(AnalyticsEvents.ClickEvent, {
      page: 'confirmation_code',
      chapter: chapter.value,
      action: 'code_correct',
    });
  } else {
    $addEvent(AnalyticsEvents.ClickEvent, {
      page: 'confirmation_code',
      chapter: chapter.value,
      action: 'code_not_correct',
    });
  }
}

async function verifyPhoneNumberAndGetJwtFunc() {
  await verifyPhoneNumberAndGetJwt();

  if (tooManyRequestsVerifyError.value) {
    resetCode();
    return;
  }

  if (verifyJwtError.value) {
    showJwtError.value = true;
    return;
  }

  if (props.register && authJwt.value) {
    emits('register', authJwt.value);
    emits('update:modelValue', false);
  } else if (authJwt.value) {
    authPhoneNumber();
  } else {
    showJwtError.value = true;
  }
}

async function authPhoneNumber() {
  await phoneNumberAuth();

  if (phoneNumberAuthError.value?.statusCode === 404) {
    if (authJwt.value) {
      registerDialogData.value = {
        phoneNumber: props.phone,
        jwtToken: authJwt.value,
        onlyCountries: props.onlyCountries,
      };

      emits('register');
      emits('update:modelValue', false);
    }

    return;
  }

  if (IdAndJwt.value) {
    phoneNumberAuthPending.value = true;
    setAuthSuccess();

    $addEvent(AnalyticsEvents.EnterLogin, {
      page: 'confirmation_code',
      chapter: chapter.value,
    });
  }
}

async function submitSmsCode() {
  if (!props.token) return;

  verifyError.value = null;
  verifyJwtError.value = null;
  showJwtError.value = false;

  if (props.auth) {
    verifyPhoneNumberAndGetJwtFunc();
  } else {
    verifyPhoneNumberFunc();
  }
}

function setSelected(event: FocusEvent) {
  (event.target as HTMLInputElement)?.select();
}

function inputEvent(event: Event) {
  const value = (event.target as HTMLInputElement)?.value;

  if (value.length > 1 && (event.target as HTMLInputElement)?.value) {
    // eslint-disable-next-line no-param-reassign
    (event.target as HTMLInputElement).value = value.substring(0, 1);
  }

  if (fullVerifyCode.value.length === verifyCodeLength.value) {
    if (blurOnComplete.value) {
      (event.target as HTMLInputElement)?.blur();

      submitSmsCode();
    } else {
      nextElement(event);
    }
  } else if (value) {
    nextElement(event);
  }
}

function isMainKeyCode(keyCode: number) {
  return keyCode >= 48 && keyCode <= 57;
}

function isTab(keyCode: number) {
  return keyCode === 9;
}

function isBackspace(keyCode: number) {
  return keyCode === 8;
}

function isMetaKey(event: KeyboardEvent, keyCode: number) {
  return event.metaKey && keyCode === 118;
}

function pasteEvent(index: number, event: ClipboardEvent) {
  const elements = (event?.target as HTMLInputElement)?.parentNode?.parentNode?.children ?? [];
  let len = 0;

  const pasteData = event.clipboardData?.getData('Text')
    .replace(/\s/g, '')
    .substring(0, elements.length - index)
    .split('') ?? [];

  for (let i = 0; i < elements.length && !Number.isNaN(parseInt(pasteData[i], 10)); i++) {
    len++;

    if ((elements[i + index]?.firstChild as HTMLInputElement)?.value) {
      (elements[i + index].firstChild as HTMLInputElement).value = pasteData[i];
    }

    verifyCode.value[i + index] = pasteData[i];
  }

  setTimeout(() => {
    if (fullVerifyCode.value.length === verifyCodeLength.value) {
      if (blurOnComplete.value && (event?.target as HTMLInputElement)?.blur) {
        (event.target as HTMLInputElement).blur();
      } else {
        previousElement(event, fullVerifyCode.value.length - 1);
      }
    } else {
      previousElement(event, index + len);
    }
  }, 0);
}

function pressEvent(event: KeyboardEvent) {
  const keyCode = event.which || event.keyCode;

  if (isMainKeyCode(keyCode) || isTab(keyCode) || isBackspace(keyCode) || isMetaKey(event, keyCode)) {
    return;
  }

  event.preventDefault();
}

function downEvent(event: KeyboardEvent) {
  const parentNode = (event.target as HTMLInputElement)?.parentNode;
  const keyCode = event.which || event.keyCode;
  let sibling: any;

  if (keyCode === 8 && !(event.target as HTMLInputElement)?.value) {
    sibling = parentNode?.previousSibling;

    if (sibling?.firstChild?.focus) {
      sibling.firstChild.focus();
    }
  } else if (keyCode >= 37 && keyCode <= 41) {
    // eslint-disable-next-line default-case
    switch (keyCode) {
      case 37:
        sibling = parentNode?.previousSibling;
        break;
      case 39:
        sibling = parentNode?.nextSibling;
        break;
    }

    if (sibling?.firstChild?.focus) {
      sibling.firstChild.focus();
    }

    event.preventDefault();
  }
}

function previousElement(event: Event, length: number) {
  const elements = (event.target as HTMLInputElement)?.parentNode?.parentNode?.children ?? [];

  if (length >= elements.length) {
    // eslint-disable-next-line no-param-reassign
    length = elements.length - 1;
  }

  if ((elements[length]?.firstChild as HTMLInputElement)?.focus) {
    (elements[length]?.firstChild as HTMLInputElement).focus();
  }
}

function nextElement(event: Event) {
  const parentNode = (event.target as HTMLInputElement)?.parentNode as HTMLInputElement;
  const nextSiblingChild = parentNode?.nextSibling?.firstChild as HTMLInputElement;

  if (nextSiblingChild?.focus) {
    nextSiblingChild.focus();
  } else if (parentNode?.focus) {
    parentNode?.focus();
  }
}

function focusInput(className: string) {
  const el: HTMLInputElement | null = document.querySelector(className);

  if (el?.focus) {
    el.focus();
  }
}

function resetCode() {
  isVerified.value = null;
  authJwt.value = null;
  verifyCode.value = new Array(verifyCodeLength.value);
  focusInput('.osk-auth-sms-dialog__code-input_first');
}

function handlePhone() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page: 'confirmation_code',
    chapter: chapter.value,
    action: 'contact_mobile',
  });
}

function handleClose() {
  emits('close');

  $addEvent(AnalyticsEvents.ClickEvent, {
    page: 'confirmation_code',
    chapter: chapter.value,
    action: 'close',
  });
}

watch(() => props.token, (value) => {
  if (!isFinishAttempts.value) {
    attemptsCounter.value++;
  }

  if (value && !isFinishAttempts.value) {
    pause();
    expirationCounter.value = attemptTimeout.value;
    disabled.value = true;
    resume();
  }

  resetCode();
});

watch(() => props.modelValue, async (value) => {
  if (value) {
    setTimeout(() => {
      focusInput('.osk-auth-sms-dialog__code-input_first');
    }, 0);

    $addEvent(AnalyticsEvents.BlockView, {
      page: 'confirmation_code',
      chapter: chapter.value,
    });
  } else {
    attemptsCounter.value = 0;

    resetCode();
  }
});

watch(showError, (value) => {
  if (value) {
    focusInput('.osk-auth-sms-dialog__code-input_last');
  }
});

watch(showJwtError, (value) => {
  if (value) {
    focusInput('.osk-auth-sms-dialog__code-input_last');
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-auth-sms-dialog {
  &.osk-dialog__body {
    @media screen and (max-height: 450px) {
      padding-top: 110px;

      .osk-auth-sms-dialog__button {
        position: static;
      }
    }

    @include media-query(lg-and-up) {
      @media screen and (max-height: 680px) {
        padding-top: 140px;
      }
    }
  }

  &__phone-text {
    @include font-style($font-size-body, normal, $font-weight-semibold);
    margin-bottom: 60px;

    @include media-query(lg-and-up) {
      margin-bottom: 72px;
    }
  }

  &__code {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__code-box {
    display: flex;
    position: relative;
    margin: 0 6px;
    width: auto;
    height: 50px;

    &:after {
      content: '';
      width: 20px;
      height: 2px;
      background: #000;
      border-radius: 10px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &_filled {
      width: auto;

      &:after {
        display: none;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    padding-top: 81px;
    position: absolute;
    bottom: var(--indent-large);
    left: var(--indent-medium);
    right: var(--indent-medium);

    @include media-query(lg-and-up) {
      padding-top: 92px;
      position: static;
    }
  }

  &__code-input {
    outline: none;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 4px;
    width: 20px;
    height: 30px;
    margin-top: auto;

    &_filled {
      width: 35px;
      height: auto;
      font-size: 52px;
      padding-bottom: 0;
    }
  }

  &__button {
    font-weight: 600;
    text-align: center;
    margin-top: var(--indent-large);
    margin-left: auto;
    margin-right: auto;

    @include media-query(lg-and-up) {
      margin-top: var(--indent-mlarge);
    }

    &:hover {
      text-decoration: underline;
    }

    &_disabled {
      color: $grey;
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      bottom: 24px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .osk-field-caption {
    position: absolute;
    top: 66px;
    left: 16px;
    right: 16px;

    @include media-query(lg-and-up) {
      left: 52px;
      right: 52px;
      top: 94px;
    }
  }

  &__error {
    border-radius: 2px;
    background: $red;
    height: 31px;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
}
</style>
