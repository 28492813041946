import {
  OrderDTO, GroupedCart, ProductDTO, OrderDTO1,
} from '~/restAPI/data-contracts';
import { useAccountService } from '~/providers/account';
import { useProductService } from '~/services/product';
import { AnalyticsEvents } from '~/types/analytics';

function addAnalyticsEvent(isAdd: boolean, product: ProductDTO) {
  const { $addEvent } = useNuxtApp();
  const eventName = isAdd ? AnalyticsEvents.CartAdd : AnalyticsEvents.CartRemove;

  $addEvent(eventName, product);
}

export function useCartStore() {
  const count = useState('cartStoreCount', () => 0);
  const cartOrders = useState<(OrderDTO | OrderDTO1)[]>('cartStorecartOrders', () => []);
  const getCartLoading = useState<boolean>('getCartLoading', () => false);

  const { fetchCart } = useAccountService();

  function updateCart(cart: GroupedCart) {
    setCartStore(cart);
  }

  function setCartStore(cart: GroupedCart) {
    count.value = cart?.count ?? 0;
    cartOrders.value = cart?.groups ?? [];
  }

  async function getCart(options?: {recalcAndApplyWithdrawBonusesToCart?: boolean, sellerId?: number}) {
    try {
      getCartLoading.value = true;

      const cart = await fetchCart(options);

      setCartStore(cart);

      return cart;
    } catch (error) {
      console.error(error);

      return false;
    } finally {
      getCartLoading.value = false;
    }
  }

  async function addToCart(query: { productId: number, sizeId?: number }, product: ProductDTO) {
    const { addProductToCart } = useProductService();

    return addProductToCart(query)
      .then((data) => {
        if (data) {
          setCartStore(data);
          addAnalyticsEvent(true, product);
        }
        return data;
      });
  }

  async function removeFromCart(orderPositionId: number, product: ProductDTO) {
    const { removeProductFromCart } = useProductService();

    return removeProductFromCart(orderPositionId)
      .then((data) => {
        if (data) {
          setCartStore(data);
          addAnalyticsEvent(false, product);
        }
        return data;
      });
  }

  return {
    count,
    cartOrders,
    updateCart,
    setCartStore,
    getCart,
    getCartLoading,
    addToCart,
    removeFromCart,
  };
}
