import type { LngLat } from '@yandex/ymaps3-types';

// TODO тестовые данные, пока нету метода
interface IOpeningHour {
  date: string
  time: string
}

// TODO тестовые данные, пока нету метода
interface IContact {
  text: string
  href: string
  type: string
}

// TODO тестовые данные, пока нету метода
export interface IBoutique {
  id: number
  coordinates: LngLat
  images: string[]
  lots: string[]
  title: string
  description?: string
  address: string
  openingHours?: IOpeningHour[]
  contacts?: IContact[]
}

// TODO тестовые данные, пока нету метода
const boutiquesServer: IBoutique[] = [
  {
    id: 111,
    coordinates: [ 37.619179, 55.761735 ],
    title: 'Бутик на Столешниковом',
    description: 'Оффлайн-пространство расположится на двух этажах, интерьер которых выполнен в лучших традициях стиля контемпорари',
    address: 'Москва, ул. Столешников переулок, д. 14',
    images: [
      'https://avatars.mds.yandex.net/get-altay/7044542/2a000001838e9e909199e531497d860d8e3e/XXL',
      'https://avatars.mds.yandex.net/get-altay/7179902/2a000001838ea02d1b143ce909b32e30f33a/XXL',
      'https://avatars.mds.yandex.net/get-altay/5455550/2a000001838ea14a10b8354886bde0ed5ba6/XXL',
      'https://avatars.mds.yandex.net/get-altay/7456447/2a000001838ea174a369afecaf9313987d6e/XXL',
      'https://avatars.mds.yandex.net/get-altay/7723428/2a000001838ea2218c9f0cab1dbb37e687f4/XXL',
      'https://avatars.mds.yandex.net/get-altay/11420721/2a0000018e57255d5f4f8a3a4078a64df048/XXL',
    ],
    lots: [
      'Новые лоты',
      'Preloved лоты',
    ],
    openingHours: [
      {
        date: 'Каждый день',
        time: '10:00 – 22:00',
      },
    ],
    contacts: [
      {
        text: 'www.oskelly.ru',
        href: 'http://oskelly.ru/',
        type: 'site',
      },
      {
        text: '+7 (499) 501-11-62',
        href: 'tel:+74995011162',
        type: 'phone',
      },
      {
        text: 'info@oskelly.ru',
        href: 'mailto:info@oskelly.ru',
        type: 'email',
      },
    ],
  },
  {
    id: 222,
    coordinates: [ 37.615242, 55.762970 ],
    title: 'Бутик на Столешниковом',
    description: 'Оффлайн-пространство расположится на двух этажах, интерьер которых выполнен в лучших традициях стиля контемпорари',
    address: 'Москва, ул. Столешников переулок, д. 14',
    images: [
      'https://avatars.mds.yandex.net/get-altay/7179902/2a000001838ea02d1b143ce909b32e30f33a/XXL',
      'https://avatars.mds.yandex.net/get-altay/7179902/2a000001838ea02d1b143ce909b32e30f33a/XXL',
    ],
    lots: [
      'Новые лоты',
    ],
  },
];

async function getBoutiquesAPI(): Promise<IBoutique[]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(boutiquesServer);
    }, 1000);
  });
}

export default function useBoutiques() {
  const {
    data: boutiquesData,
    execute: getBoutiques,
    pending: getBoutiquesPending,
  } = useLazyAsyncData(() => getBoutiquesAPI(), { immediate: false });

  getBoutiquesPending.value = false;

  return {
    boutiquesData,
    getBoutiques,
    getBoutiquesPending,
  };
}
