<template>
  <UikitDialog
    v-model="model"
    position="right"
    :full-height="laptopM || isDesktop"
    no-padding
    class="osk-points-join-dialog"
  >
    <div class="osk-dialog-wrapper">
      <div class="osk-points-join-dialog__title">
        {{ $t('components.pointsJoinProgramDialog.title') }}
      </div>

      <div class="osk-points-join-dialog__text">
        {{ $t('components.pointsJoinProgramDialog.text') }}
      </div>

      <a
        :href="OSKELLY_LOYALTY_PDF"
        class="osk-points-join-dialog__link"
        target="_blank"
      >{{ $t('components.pointsJoinProgramDialog.link') }}</a>

      <UikitButton
        full-width
        class="osk-mt-mlg"
        :size="isDesktop ? 'm' : 'l'"
        :loading="joinLoading"
        :disabled="refuseLoading"
        @click="setLoyalty(true)"
      >
        {{ $t('components.pointsJoinProgramDialog.join') }}
      </UikitButton>

      <UikitButton
        full-width
        color="secondary"
        class="osk-mt-cp"
        :size="isDesktop ? 'm' : 'l'"
        :loading="refuseLoading"
        :disabled="joinLoading"
        @click="setLoyalty(false)"
      >
        {{ $t('components.pointsJoinProgramDialog.refuse') }}
      </UikitButton>
    </div>
  </UikitDialog>
</template>

<script setup lang="ts">
import { useApi } from '~/restAPI';
import { Account } from '~/restAPI/Account';

import { OSKELLY_LOYALTY_PDF } from '~/data/consts';

const model = defineModel<boolean>({ required: true });

const emits = defineEmits<{
  agree: []
}>();

const { createApiInstance } = useApi();
const accountApi = createApiInstance(Account);

const isLoyaltyAccepted = ref(false);
const joinLoading = ref(false);
const refuseLoading = ref(false);

const { laptopM, isDesktop } = useUiBreakpoints();
const { getAccount, account } = useAccountStore();

const {
  pending: setLoyaltyLoading,
  refresh: setLoyaltyRequest,
} = useLazyAsyncData(() => accountApi.setLoyaltyAcceptanceUsingPut({
  isLoyaltyProgramAccepted: isLoyaltyAccepted.value,
}, { format: 'json' }).then((res) => res.data.data), { immediate: false });

setLoyaltyLoading.value = false;

function closeDialog() {
  model.value = false;
}

async function setLoyalty(isLoayalty: boolean) {
  if (isLoayalty) {
    joinLoading.value = true;
  } else {
    refuseLoading.value = true;
  }

  isLoyaltyAccepted.value = isLoayalty;
  await setLoyaltyRequest();
  await getAccount();

  joinLoading.value = false;
  refuseLoading.value = false;

  if (isLoayalty && account.value.isLoyaltyProgramAccepted) {
    emits('agree');
  }

  closeDialog();
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-points-join-dialog {
  z-index: 1010 !important;

  .osk-dialog-wrapper {
    justify-content: center;
    align-items: flex-start;

    @media screen and (max-height: 375px) {
      justify-content: flex-start;
      padding-top: 64px;
    }

    @include media-query(lg-and-up) {
      @media screen and (max-height: 540px) {
        justify-content: flex-start;
        padding-top: 94px;
        padding-bottom: 54px;
      }
    }
  }

  .osk-dialog__close {
    @media screen and (max-width: 767px) {
      top: 12px;
      right: 12px;
    }
  }

  &__title {
    @include font-style(var(--font-size-heading), var(--line-height-heading), var(--font-weight-bold));
    padding: 10px 0;
    margin-top: 27px;
    margin-bottom: 22px;
  }

  &__text {
    line-height: var(--line-height-subheading);
  }

  &__link {
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    font-weight: var(--font-weight-semibold);

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
