<template>
  <div class="osk-header-search-results">
    <HeaderSearchHistory
      v-if="isShowHistory"
      :has-padding="hasPadding"
      :products="historyProducts"
      :users="historyUsers"
      @product-click="historyProductClick"
      @user-click="historyUserClick"
    />

    <div
      v-if="isShowResults"
      class="osk-header-search-results__results"
    >
      <HeaderSearchSuggestionsTabs
        v-if="isVisibleSuggestionsTabs"
        v-model="activeSuggestionTab"
        class="osk-header-search-results__suggestions-tabs"
        @update:model-value="onChangedSuggestionTab"
      />

      <HeaderSearchParentCategoryTabs
        v-model="activeCategoryTab"
        class="osk-header-search-results__tabs"
        @update:model-value="onChangedParentCategoryTab"
      />

      <div class="osk-header-search-results__result-content">
        <UikitLoaderInner
          v-if="isResultLoading"
          class="osk-header-search-results__loader"
          small
        />

        <template v-else-if="isShowSuggestions">
          <HeaderSearchSuggestions
            v-if="hasResults"
            :has-padding="hasPadding"
            :products="resultProducts"
            :users="resultUsers"
            @product-click="productClick"
            @user-click="userClick"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  UserSearchItem,
  TProductSearchItem,
} from '~/types/components/search';

import { AnalyticsEvents } from '~/types/analytics';

import { TGenderPagesValues } from '~/types/main';
import { MAPPING_PARENT_CATEGORY_FOR_BACKEND_NAMES } from 'assets/constants';

const emits = defineEmits<{
  (e: 'clickLink'): void,
}>();

const {
  headerSearchQuery,
  activeCategoryTab,
  activeSuggestionTab,
  productsData,
  usersData,
  historyProducts,
  historyUsers,
  resultProducts,
  resultUsers,
  isResultLoading,
  hasResults,
  isHeaderSearchFull,
  isHeaderSearchMobileDialog,
  isShowResults,
  searchId,
  globalSearchId,
  usageSearchId,
  isGoToNextPageFromSearch,
  isShowHistory,
  isShowSuggestions,
  goToCatalog,
  handleProductClick,
  goToUser,
  handleUserClick,
  showResults,
} = useHeaderSearch();
const { preventBodyScroll } = usePreventScroll();
const { activeMenuCategory } = useActiveMenuCategory();
const { isDesktop } = useUiBreakpoints();
const { FULLTEXT_SEARCH_WEB_ON } = useExperimentsStore();
const { isCatalog } = usePageDefinition();
const { parentCategoriesSearchTabs } = useCategories();

const { $addEvent } = useNuxtApp();
const route = useRoute();

const activeTabCategoryId = computed(() => parentCategoriesSearchTabs.value.find((item) => item.id === activeCategoryTab.value)?.categoryId);

const hasPadding = computed(() => {
  if (!historyProducts.value && !historyUsers.value) return false;

  const sum = (historyProducts.value?.length ?? 0) + (historyUsers.value?.length ?? 0);

  return sum < 5;
});

const isVisibleSuggestionsTabs = computed(() => FULLTEXT_SEARCH_WEB_ON.value && !isCatalog.value);

function handleItemClick(item: 'item' | 'user', historySearchValue?: string) {
  isGoToNextPageFromSearch.value = true;
  usageSearchId.value = globalSearchId.value;

  emits('clickLink');

  $addEvent(AnalyticsEvents.ClickEvent, {
    page: 'search',
    value: historySearchValue ?? headerSearchQuery.value,
    id_item: globalSearchId.value,
    item,
    action: 'suggest',
    chapter: historySearchValue ? 'search _history' : undefined,
  });
}

function historyProductClick(item: TProductSearchItem) {
  handleItemClick('item', item);
  handleProductClick(item);
}

function historyUserClick(item: UserSearchItem) {
  handleItemClick('user', item.nickname);
  handleUserClick(item);
}

function productClick(item: TProductSearchItem, index: number) {
  handleItemClick('item');
  handleProductClick(item, index);
}

function userClick(item: UserSearchItem, index: number) {
  handleItemClick('user');
  handleUserClick(item, index);
}

function onChangedSuggestionTab() {
  showResults();
}

async function onChangedParentCategoryTab(value: NonNullable<TGenderPagesValues>) {
  await showResults();

  $addEvent(AnalyticsEvents.ClickEvent, {
    page: 'search',
    value: headerSearchQuery.value,
    id_item: globalSearchId.value,
    chapter: MAPPING_PARENT_CATEGORY_FOR_BACKEND_NAMES[value],
    id_chapter: String(activeTabCategoryId.value),
  });
}

watch(isHeaderSearchFull, async (value) => {
  if (!isDesktop.value) {
    return;
  }

  if (value) {
    activeCategoryTab.value = activeMenuCategory.value ?? 'women';

    showResults();
  }
}, { immediate: true });

watchDebounced(headerSearchQuery, async (value) => {
  productsData.value = null;
  usersData.value = null;

  if (value && (isHeaderSearchFull.value || isHeaderSearchMobileDialog.value)) {
    await showResults();
  }

  if (!value) {
    searchId.value = null;
    return;
  }

  if (hasResults.value) {
    $addEvent(AnalyticsEvents.ClickEvent, {
      page: 'search',
      value,
      id_item: globalSearchId.value,
    });
  } else {
    $addEvent(AnalyticsEvents.BlockView, {
      page: 'search',
      value,
      id_item: globalSearchId.value,
      action: 'zero_result',
    });
  }
}, { debounce: 300 });

watch(isDesktop, (value) => {
  if (!value) {
    preventBodyScroll(false);
    isHeaderSearchFull.value = false;
  }
});

if (FULLTEXT_SEARCH_WEB_ON.value) {
  watch(() => isCatalog.value, (value) => {
    if (value) {
      // на странице каталога поиск только по товарам
      activeSuggestionTab.value = 'products';
    }
  }, { immediate: true });
}

onUnmounted(() => {
  searchId.value = null;

  if (!isGoToNextPageFromSearch.value) {
    usageSearchId.value = null;
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-search-results {
  .os-scrollbar {
    --os-handle-min-size: 150px;
    --os-handle-perpendicular-size: 2px;
    --os-handle-bg: rgba(0, 0, 0, .6)
  }

  .os-scrollbar-horizontal {
    display: none;
  }

  &__results {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include media-query(lg-and-up) {
      width: var(--width-full-search);
      margin: 0 auto -52px;
    }
  }

  &__result-content {
    position: relative;
    min-height: 65px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include media-query(lg-and-up) {
      height: auto;
      display: block;
      padding-left: 12px;
    }
  }

  &__tabs {
    margin-bottom: var(--indent-compact);
  }

  &__suggestions-tabs {
    margin-bottom: var(--indent-large);
  }
}
</style>
