<template>
  <div
    :class="{
      'osk-divider': true,
      [colorClass]: true,
    }"
  />
</template>

<script setup lang="ts">
import type { TColors } from '~/types/components/colors';

const props = withDefaults(defineProps<{
  color?: TColors
}>(), {
  color: 'grey-3',
});

const colorClass = computed(() => `bg-${props.color}`);
</script>

<style lang="scss">
.osk-divider {
  width: 100%;
  height: .5px;
}
</style>
