import { PrimaryPageType } from '~/restAPI/data-contracts';

export default defineNuxtRouteMiddleware((to, from) => {
  const { gender, genderPages, setGender } = useGender();
  const isServerRedirect = useState('isServerRedirectMainPage', () => false);

  const { activeMenuCategory, stopChangingActiveMenu } = useActiveMenuCategory();

  if (!gender.value && activeMenuCategory.value) {
    stopChangingActiveMenu.value = true;
    setGender(activeMenuCategory.value);
  }

  if (gender.value && !from.meta.communityDialog && !to.meta.communityDialog && !to.meta.loyalty) {
    if (process.server) isServerRedirect.value = true;

    switch (gender.value) {
      case genderPages[PrimaryPageType.FEMALE]:
        if (from.name === genderPages[PrimaryPageType.FEMALE]) {
          return abortNavigation();
        }

        return navigateTo({
          path: `/${genderPages[PrimaryPageType.FEMALE]}`,
          query: from.query,
        }, { redirectCode: 307 });

      case genderPages[PrimaryPageType.MALE]:
        if (from.name === genderPages[PrimaryPageType.MALE]) {
          return abortNavigation();
        }

        return navigateTo({
          path: `/${genderPages[PrimaryPageType.MALE]}`,
          query: from.query,
        }, { redirectCode: 307 });

      case genderPages[PrimaryPageType.CHILD]:
        if (from.name === genderPages[PrimaryPageType.CHILD]) {
          return abortNavigation();
        }

        return navigateTo({
          path: `/${genderPages[PrimaryPageType.CHILD]}`,
          query: from.query,
        }, { redirectCode: 307 });

      case genderPages[PrimaryPageType.LIFESTYLE]:
        if (from.name === genderPages[PrimaryPageType.LIFESTYLE]) {
          return abortNavigation();
        }

        return navigateTo({
          path: `/${genderPages[PrimaryPageType.LIFESTYLE]}`,
          query: from.query,
        }, { redirectCode: 307 });
      default:
    }
  }

  return true;
});
