<template>
  <section
    class="osk-benefits"
  >
    <div class="osk-wrapper">
      <h2 class="osk-benefits__title">
        {{ $t('components.home.benefits.title') }}
      </h2>

      <ul class="osk-benefits__list">
        <li class="osk-benefits__item">
          <UikitIcon
            name="Menu"
            size="l"
            class="osk-benefits__icon"
          />

          <div class="osk-benefits__block">
            <div class="osk-benefits__block-title">
              {{ $t('components.home.benefits.block1.title') }}
            </div>

            <div class="osk-benefits__block-text">
              {{ $t('components.home.benefits.block1.text') }}
            </div>
          </div>
        </li>

        <li class="osk-benefits__item">
          <UikitIcon
            name="Box"
            size="l"
            class="osk-benefits__icon"
          />

          <div class="osk-benefits__block">
            <div class="osk-benefits__block-title">
              {{ $t('components.home.benefits.block2.title') }}
            </div>

            <div class="osk-benefits__block-text">
              {{ $t('components.home.benefits.block2.text') }}
            </div>
          </div>
        </li>

        <li class="osk-benefits__item">
          <UikitIcon
            name="Card"
            size="l"
            class="osk-benefits__icon"
          />

          <div class="osk-benefits__block">
            <div class="osk-benefits__block-title">
              {{ $t('common.secureTransaction') }}
            </div>

            <div class="osk-benefits__block-text">
              {{ $t('components.home.benefits.block3.text') }}
            </div>
          </div>
        </li>

        <li class="osk-benefits__item">
          <UikitIcon
            name="Phone"
            size="l"
            class="osk-benefits__icon"
          />

          <div class="osk-benefits__block">
            <div class="osk-benefits__block-title">
              {{ $t('components.home.benefits.block4.title') }}
            </div>

            <div class="osk-benefits__block-text">
              {{ $t('components.home.benefits.block4.text') }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup lang="ts">

</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-benefits {
  &__title {
    @include font-heading;
    padding: 42px 0 30px;

    @include media-query(lg-and-up) {
      text-transform: uppercase;
      text-align: center;
      padding: 82px 0 32px;
    }
  }

  &__list {
    @include media-query(lg-and-up) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: $indent-mlarge;
    }

    @include media-query(lg-and-up) {
      max-width: 218px;
      flex-shrink: 0;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    margin-right: $indent-medium;

    @include media-query(lg-and-up) {
      margin-bottom: $indent-mmedium;
    }
  }

  &__block-title {
    @include font-style($font-size-subheading, $line-height-subheading, $font-weight-bold);
    margin-bottom: $indent-small;

    @include media-query(lg-and-up) {
      margin-bottom: $indent-medium;
    }
  }

  &__block-text {
    line-height: $line-height-subheading;
    max-width: 436px;

  }
}
</style>
