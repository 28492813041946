import { useApi } from '~/restAPI';
import { Search } from '~/restAPI/Search';
import { Products } from '~/restAPI/Products';
import {
  UserSearchItem,
  TSuggestionType,
  TProductSearchItem,
  ProductSearchQuery,
  ProductSearchQueryV2,
  ProductsSearchResponse,
  ProductSearchItem,
} from '~/types/components/search';
import {
  MAPPING_PARENT_CATEGORY_FOR_BACKEND_NAMES,
  MIN_SEARCH_QUERY_LENGTH,
  SEARCH_SIZE_PARAMS,
} from 'assets/constants';
import { TGenderPagesValues } from '~/types/main';
import { Api2ResponseOfListOfString } from '~/restAPI/data-contracts';
import { AnalyticsEvents } from '~/types/analytics';

export const useHeaderSearch = () => {
  const { $addEvent } = useNuxtApp();
  const { FULLTEXT_SEARCH_WEB_ON } = useExperimentsStore();
  const { isCatalog, isMain, isSecondMain } = usePageDefinition();
  const config = useRuntimeConfig();
  const route = useRoute();
  const { parentCategoriesURLForNameMap, parentCategoriesIdForNameMap } = useCategories();
  const { createApiInstance: createSearchApiInstance } = useApi(process.dev ? undefined : config.public.SEARCH_SRV_API_PATH);
  const { createApiInstance: createProductsApiInstance } = useApi();
  const searchApi = createSearchApiInstance(Search);
  const productsApi = createProductsApiInstance(Products);

  const cancelHistory = 'cancelHistory';
  const cancelSearchProducts = 'cancelSearchProducts';
  const cancelSearchUsers = 'cancelSearchUsers';

  const activeCategoryTab = useState<NonNullable<TGenderPagesValues>>('headerSearchActiveCategoryTab', () => 'women');
  const activeSuggestionTab = useState<TSuggestionType>('headerSearchActiveSuggestionTab', () => 'products');
  const headerSearchQuery = useState('headerSearchQuery', () => '');
  const historyProducts = useState<ProductSearchItem[] | TProductSearchItem[] | undefined>('headerSearchHistoryProductsData', () => undefined);
  const historyUsers = useState<UserSearchItem[] | undefined>('headerSearchHistoryUsersData', () => undefined);
  const historyPending = useState('headerSearchHistoryPending', () => false);
  const searchId = useState<string | null>('searchId', () => null);
  const historySearchId = useState<string | null>('historySearchId', () => null);
  const usageSearchId = useState<string | null>('usageSearchId', () => null);
  const isGoToNextPageFromSearch = useState<boolean>('isGoToNextPageFromSearch', () => false);
  const pagePathWhereMadeSearchQuery = useState<string>('pagePathWhereMadeSearchQuery', () => '');

  const { isHeaderSearchFull, isHeaderSearchMobileDialog } = useHeader();

  const searchProductQuery = computed<ProductSearchQuery | ProductSearchQueryV2>(() => {
    if (FULLTEXT_SEARCH_WEB_ON.value) {
      return {
        userQuery: headerSearchQuery.value,
      };
    }

    return {
      base: MAPPING_PARENT_CATEGORY_FOR_BACKEND_NAMES[activeCategoryTab.value],
      query: headerSearchQuery.value,
    };
  });

  const {
    data: productsData,
    pending: productsPending,
    execute: getProducts,
  } = useAsyncData(() => {
    const params = {
      format: 'json',
      cancelToken: cancelSearchProducts,
    };

    if (FULLTEXT_SEARCH_WEB_ON.value) {
      try {
        return productsApi
          .getApiV2ProductsSearchGetSuggestions({
            ...searchProductQuery.value as ProductSearchQueryV2,
            ...SEARCH_SIZE_PARAMS,
          }, params)
          .then((data) => data.data);
      } catch (error) {
        console.error(error);
        return {};
      }
    }

    return searchApi
      .searchProductUsingGet({
        ...searchProductQuery.value as ProductSearchQuery,
        ...SEARCH_SIZE_PARAMS,
      }, params)
      .then((data) => data.data);
  }, { immediate: false });

  const {
    data: usersData,
    pending: usersPending,
    execute: getUsers,
  } = useAsyncData(() => searchApi
    .searchUserUsingGet({
      query: headerSearchQuery.value,
      ...SEARCH_SIZE_PARAMS,
    }, {
      format: 'json',
      cancelToken: cancelSearchUsers,
    })
    .then((data) => data!.data!), { immediate: false });

  productsPending.value = false;
  usersPending.value = false;

  const isProductsActiveSuggestionTab = computed(() => activeSuggestionTab.value === 'products');
  const isUsersActiveSuggestionTab = computed(() => activeSuggestionTab.value === 'users');
  const globalSearchId = computed(() => searchId.value ?? historySearchId.value);

  const hasHistory = computed(() => !!historyProducts.value?.length || !!historyUsers.value?.length);
  const isShowHistory = computed(() => headerSearchQuery.value.length < MIN_SEARCH_QUERY_LENGTH && hasHistory.value);
  const isShowSuggestions = computed(() => productsData.value || usersData.value);

  const resultProducts = computed(() => {
    if (FULLTEXT_SEARCH_WEB_ON.value) {
      return isProductsActiveSuggestionTab.value ? (productsData.value as Api2ResponseOfListOfString)?.data?.values : [];
    }

    return (productsData.value as ProductsSearchResponse)?.values;
  });
  const resultUsers = computed(() => {
    if (FULLTEXT_SEARCH_WEB_ON.value) {
      return isUsersActiveSuggestionTab.value ? usersData.value?.values : [];
    }

    return usersData.value?.values || [];
  });
  const isResultLoading = computed(() => productsPending.value || usersPending.value);

  const hasResults = computed(() => !!resultProducts.value?.length || !!resultUsers.value?.length);
  const isShowResults = computed(() => headerSearchQuery.value.length >= MIN_SEARCH_QUERY_LENGTH || (!hasHistory.value && (productsData.value || usersData.value)));

  function getProductsHistory(params: RequestParams) {
    try {
      return FULLTEXT_SEARCH_WEB_ON.value
        ? productsApi
          .getApiV2ProductsSearchGetHistory(params)
          .then((result) => result.data)
        : searchApi
          .getHistoryProductsUsingGet(SEARCH_SIZE_PARAMS, params)
          .then((result) => result.data);
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  async function getHistory() {
    historyPending.value = true;

    const params = {
      format: 'json',
      cancelToken: cancelHistory,
    };

    const { data } = await useAsyncData(() => Promise.allSettled([
      getProductsHistory(params),
      searchApi
        .getHistoryUsersUsingGet(SEARCH_SIZE_PARAMS, params)
        .then((result) => result.data),
    ]));

    if (FULLTEXT_SEARCH_WEB_ON.value) {
      const historyProductsData = data.value?.[0].value as Api2ResponseOfListOfString;
      const historyUsersData = data.value?.[1].value;

      historyProducts.value = historyProductsData?.data?.values as TProductSearchItem[] || undefined;
      historyUsers.value = historyUsersData?.values || undefined;

      if (historyProductsData?.data?.id && historyUsersData?.id) {
        historySearchId.value = `${historyProductsData.data?.id}-${historyUsersData?.id}`;
      }
    } else {
      historySearchId.value = data.value?.map((d) => d.id).join('-') ?? null;

      historyProducts.value = (data.value?.[0] as ProductsSearchResponse).values;
      historyUsers.value = data.value?.[1].values;
    }

    historyPending.value = false;
  }

  async function getResults(onlyProducts?: boolean) {
    if (FULLTEXT_SEARCH_WEB_ON.value) {
      if (isProductsActiveSuggestionTab.value) {
        await getProducts();
      } else {
        await getUsers();
      }
    } else {
      await Promise.all([
        getProducts(),
        onlyProducts ? null : getUsers(),
      ]);
    }
  }

  async function showResults(onlyProducts?: boolean) {
    if (historyPending.value && headerSearchQuery.value.length >= MIN_SEARCH_QUERY_LENGTH) {
      if (FULLTEXT_SEARCH_WEB_ON.value) {
        productsApi.abortRequest(cancelHistory);
      } else {
        searchApi.abortRequest(cancelHistory);
      }
    }

    if (isResultLoading.value) {
      if (FULLTEXT_SEARCH_WEB_ON.value) {
        productsApi.abortRequest(cancelSearchProducts);
        productsApi.abortRequest(cancelSearchUsers);
      } else {
        searchApi.abortRequest(cancelSearchProducts);
        searchApi.abortRequest(cancelSearchUsers);
      }
    }

    if (!historyPending.value && headerSearchQuery.value.length < MIN_SEARCH_QUERY_LENGTH && !hasHistory.value) {
      await getHistory();
    }

    if (headerSearchQuery.value.length >= MIN_SEARCH_QUERY_LENGTH) {
      await getResults(onlyProducts);

      if (FULLTEXT_SEARCH_WEB_ON.value) {
        searchId.value = `${(productsData.value as Api2ResponseOfListOfString)?.data?.id}-${usersData.value?.id}`;
      } else {
        searchId.value = `${(productsData.value as ProductsSearchResponse)?.id}-${usersData.value?.id}`;
      }
    } else {
      searchId.value = null;
    }
  }

  function handleError(err:unknown) {
    throw err;
  }

  async function goToCatalog(productItem: ProductSearchItem | TProductSearchItem) {
    if (FULLTEXT_SEARCH_WEB_ON.value) {
      const product = productItem as ProductSearchItem | TProductSearchItem;
      const homeBannerId = route.params.homeBannerId ? `&homeBannerId=${route.params.homeBannerId}` : '';
      const searchQueryString = encodeURI(product.category_id ? product.search_value : product);
      const categoryUrl = product.category_id
        ? parentCategoriesIdForNameMap.value[product.category_id]
        : parentCategoriesURLForNameMap.value[activeCategoryTab.value];
      const query = {
        ...route.query,
        search: searchQueryString,
      };

      if (query.page) {
        delete query.page;
      }

      const queryString = Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join('&');

      pagePathWhereMadeSearchQuery.value = route.path;

      await navigateTo(`/catalog/${categoryUrl}?${queryString}${homeBannerId}`);
    } else {
      const product = productItem as ProductSearchItem;
      const brand = product.brand_id ? `brand=${product.brand_id}` : '';
      const category = product.category_id ? `categoriesIds=${product.category_id}` : '';
      const filter = product.attribute_value_ids ? `filter=${product.attribute_value_ids.join('%2C')}` : '';

      const model = product.product_model_id ? `model=${product.product_model_id}` : '';

      if (brand || category || filter || model) {
        const params = [
          brand,
          category,
          filter,
          model,
        ].filter((str) => str !== '').join('&');

        await navigateTo(`/catalog/${product.url ? product.url : product.base}?${params}`);
      }
    }

    isHeaderSearchFull.value = false;
    headerSearchQuery.value = '';
  }

  async function handleProductClick(product: ProductSearchItem | TProductSearchItem, index?: number) {
    let resultId;

    if (FULLTEXT_SEARCH_WEB_ON.value) {
      resultId = (productsData.value as Api2ResponseOfListOfString)?.data?.id ?? '';
    } else {
      resultId = (productsData.value as ProductsSearchResponse)?.id ?? '';
    }

    try {
      searchApi
        .saveProductSearchHistoryUsingGet({
          base: MAPPING_PARENT_CATEGORY_FOR_BACKEND_NAMES[activeCategoryTab.value],
          query: headerSearchQuery.value || product,
          result_id: resultId,
          result_select: index ?? 0,
          ...SEARCH_SIZE_PARAMS,
        }, { format: 'json' })
        .then((data) => data.data);
    } catch (err) {
      handleError(err);
    }

    await goToCatalog(product);
    clearHistory();
  }

  function goToUser(user: UserSearchItem) {
    navigateTo(`/profile/${user.id}`);

    isHeaderSearchFull.value = false;
    headerSearchQuery.value = '';
  }

  async function handleUserClick(user: UserSearchItem, index: number) {
    try {
      searchApi
        .saveUserSearchHistoryUsingGet({
          query: headerSearchQuery.value,
          result_id: usersData.value?.id ?? '',
          result_select: index,
          ...SEARCH_SIZE_PARAMS,
        }, { format: 'json' })
        .then((data) => data!.data!);
    } catch (err) {
      handleError(err);
    }

    goToUser(user);
    clearHistory();
  }

  function clearHistory() {
    historyProducts.value = undefined;
    historyUsers.value = undefined;
  }

  function addSearchInputClickAnalyticsEvent() {
    if (FULLTEXT_SEARCH_WEB_ON.value) {
      let options;

      if (isCatalog.value) {
        options = {
          page: 'catalog_main',
          action: 'search',
        };
      } else if (isMain.value) {
        options = {
          page: 'main',
          item: 'search',
        };
      } else if (isSecondMain.value) {
        options = {
          page: 'second_main',
          item: 'search',
        };
      }

      if (options) {
        $addEvent(AnalyticsEvents.ClickEvent, options);
      }
    }
  }

  function addCloseSearchInputClickAnalyticsEvent() {
    if (FULLTEXT_SEARCH_WEB_ON.value) {
      let options;

      if (isCatalog.value) {
        options = {
          page: 'search',
          action: 'cancel',
        };
      } else if (isMain.value) {
        options = {
          page: 'search',
          action: 'cancel',
        };
      } else if (isSecondMain.value) {
        options = {
          page: 'search',
          action: 'cancel',
        };
      }

      if (options) {
        $addEvent(AnalyticsEvents.ClickEvent, options);
      }
    }
  }

  function addInputSearchTextAnalyticsEvent() {
    if (FULLTEXT_SEARCH_WEB_ON.value) {
      let options;

      if (isCatalog.value) {
        options = {
          page: 'search',
          id_item: globalSearchId.value,
          value: headerSearchQuery.value,
        };
      } else if (isMain.value) {
        options = {
          page: 'search',
          id_item: globalSearchId.value,
          value: headerSearchQuery.value,
        };
      } else if (isSecondMain.value) {
        options = {
          page: 'search',
          id_item: globalSearchId.value,
          value: headerSearchQuery.value,
        };
      }

      if (options) {
        $addEvent(AnalyticsEvents.ClickEvent, options);
      }
    }
  }

  /** Аналитика отправляется при переходе на предыдущую страницу */
  function addBackwardAnalyticsEvent() {
    if (FULLTEXT_SEARCH_WEB_ON.value) {
      if (pagePathWhereMadeSearchQuery.value === window.location.pathname) {
        let options;

        if (isCatalog.value && route.query?.search) {
          options = {
            page: 'catalog_search',
            action: 'back',
          };
        } else if (isSecondMain.value) {
          options = {
            page: 'second_main',
            action: 'back',
          };
        }

        if (options) {
          $addEvent(AnalyticsEvents.ClickEvent, options);
        }
      }

      pagePathWhereMadeSearchQuery.value = '';
    }
  }

  function handleEnter() {
    if (!isResultLoading.value) {
      if (FULLTEXT_SEARCH_WEB_ON.value) {
        return handleProductClick(headerSearchQuery.value as TProductSearchItem);
      } if (hasResults.value) {
        if ((resultProducts.value as ProductSearchItem[])?.length) {
          return handleProductClick(resultProducts.value![0], 0);
        } if (resultUsers.value?.length) {
          return handleUserClick(resultUsers.value![0], 0);
        }
      }
    }

    return Promise.resolve();
  }

  return {
    headerSearchQuery,
    activeCategoryTab,
    activeSuggestionTab,
    historyPending,
    productsData,
    usersData,
    historyProducts,
    historyUsers,
    hasHistory,
    resultProducts,
    resultUsers,
    isResultLoading,
    hasResults,
    isHeaderSearchFull,
    isHeaderSearchMobileDialog,
    isShowResults,
    searchId,
    historySearchId,
    globalSearchId,
    usageSearchId,
    isGoToNextPageFromSearch,
    isShowHistory,
    isShowSuggestions,
    isProductsActiveSuggestionTab,
    isUsersActiveSuggestionTab,
    pagePathWhereMadeSearchQuery,
    showResults,
    goToCatalog,
    goToUser,
    handleProductClick,
    handleUserClick,
    handleEnter,
    addSearchInputClickAnalyticsEvent,
    addCloseSearchInputClickAnalyticsEvent,
    addInputSearchTextAnalyticsEvent,
    addBackwardAnalyticsEvent,
  };
};
