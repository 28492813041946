/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Api2ResponseOfBonusesBalanceDTO,
  Api2ResponseOfBonusesBonusCardInfoDTO,
  Api2ResponseOfBonusesBonusCardWalletDTO,
  Api2ResponseOfListOfBonusesBurningScheduleItemDTO,
  Api2ResponseOfPageOfBonusesTransactionDTO,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Bonuses<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags bonuses-controller-api
   * @name GetBalanceBrief
   * @summary Ручка для отображения текущего баланса + инфа о сгорающих баллах
   * @request GET:/api/v2/bonuses/balance-brief
   */
  getBalanceBrief = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfBonusesBalanceDTO, any>({
      path: `/api/v2/bonuses/balance-brief`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags bonuses-controller-api
   * @name GetBalanceBurningSchedule
   * @summary Ручка для отображения плана сгорания бонусов
   * @request GET:/api/v2/bonuses/balance-burning-schedule
   */
  getBalanceBurningSchedule = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfBonusesBurningScheduleItemDTO, any>({
      path: `/api/v2/bonuses/balance-burning-schedule`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags bonuses-controller-api
   * @name GetHistory
   * @summary Ручка для получения истории начислений и списаний
   * @request GET:/api/v2/bonuses/history
   */
  getHistory = (
    query?: {
      /**
       * Номер страницы
       * @format int32
       */
      pageNumber?: number;
      /**
       * Размер страницы
       * @format int32
       */
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfBonusesTransactionDTO, any>({
      path: `/api/v2/bonuses/history`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags bonuses-controller-api
   * @name GetBonusCardInfo
   * @summary Ручка для получения инфы о бонусной карте (баркода)
   * @request GET:/api/v2/bonuses/bonus-card-info
   */
  getBonusCardInfo = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfBonusesBonusCardInfoDTO, any>({
      path: `/api/v2/bonuses/bonus-card-info`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags bonuses-controller-api
   * @name GetBonusCardWallet
   * @summary Ручка для получения инфы о бонусной карте (ссылка для добавления в Apple Wallet)
   * @request GET:/api/v2/bonuses/bonus-card-wallet
   */
  getBonusCardWallet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfBonusesBonusCardWalletDTO, any>({
      path: `/api/v2/bonuses/bonus-card-wallet`,
      method: "GET",
      format: "json",
      ...params,
    });
}
