<template>
  <div class="osk-points-history">
    <div class="osk-dialog-title">
      {{ $t('business.user.PointsHistory.title') }}
    </div>

    <ul class="osk-points-history__list">
      <li
        v-for="(item, index) in historyList"
        :key="index"
        class="osk-points-history__item"
      >
        <div class="osk-points-history__item-left">
          <span>
            {{ item.typeStr }}
          </span>

          <span
            v-if="item.orderId"
            class="osk-points-history__item-text"
          >
            {{ $t('business.user.PointsHistory.orderText', { id: item.orderId, date: formatDate(item.orderDate, 'D MMM YYYY') }) }}
          </span>

          <span
            v-else-if="item.description"
            class="osk-points-history__item-text"
          >
            {{ item.description }}
          </span>
        </div>

        <div v-if="item.type && item.amount?.total">
          {{ isMinus(item.type) ? '–' : '+' }}{{ numberFormat(item.amount.total) }}
        </div>
      </li>
    </ul>

    <UikitButton
      full-width
      outline
      size="m"
      color="white"
      class="osk-mb-mlg"
      @click="handleShowAll"
    >
      {{ $t('business.user.PointsHistory.button') }}
    </UikitButton>

    <UikitDivider color="grey-5" />

    <BusinessPointsQrDialog v-model="isDialog" />
  </div>
</template>

<script lang="ts" setup>
import { AnalyticsEvents } from '~/types/analytics';

import type { BonusesTransactionDTO } from '~/restAPI/data-contracts';
import { BonusesTransactionType } from '~/restAPI/data-contracts';

const props = defineProps<{
  historyList?: BonusesTransactionDTO[]
  eventPoints?: string
  page?: string
}>();

const isDialog = ref(false);

const { formatDate } = useDateFormatter();
const { numberFormat } = useNumberFormatter();
const { $addEvent } = useNuxtApp();

function isMinus(type: BonusesTransactionType) {
  return ([ BonusesTransactionType.WITHDRAW, BonusesTransactionType.EXPIRE ]).includes(type);
}

function handleShowAll() {
  isDialog.value = true;

  $addEvent(AnalyticsEvents.ClickEvent, {
    page: props.page,
    value: props.eventPoints,
    action: 'loyal_history',
  });
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-points-history {
  position: relative;
  margin-top: -12px;

  &__title {
    @include font-style(var(--font-size-heading), var(--line-height-heading), var(--font-weight-bold));
    padding: 10px 0;
  }

  &__list {
    margin-bottom: var(--indent-medium);
  }

  &__item {
    @include font-style(var(--font-size-body), var(--line-height-subheading), var(--font-weight-medium));
    padding: var(--indent-medium) 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__item-left {
    display: flex;
    flex-direction: column;
  }

  &__item-text {
    margin-top: 6px;
    color: var(--grey);
  }
}
</style>
