<template>
  <UikitDialog
    v-model="model"
    position="right"
    full-height
    no-padding
    dark
    content-class="osk-activate-code-dialog"
    @handle-close="handleClose"
  >
    <Transition
      name="slide-right"
      mode="out-in"
    >
      <div
        v-if="showSuccess"
        class="osk-dialog-wrapper"
      >
        <div class="osk-dialog-title osk-mb-xs">
          {{ $t('business.user.PointsActivateCodeDialog.success.title') }}
        </div>

        <div class="osk-activate-code-dialog__text osk-mb-mlg">
          {{ $t('business.user.PointsActivateCodeDialog.success.description') }}
        </div>

        <UikitButton
          class="osk-activate-code-dialog__success-button"
          full-width
          color="grey-6"
          @click="handleGlobalClose"
        >
          {{ $t('business.user.PointsActivateCodeDialog.success.button') }}
        </UikitButton>
      </div>

      <div
        v-else
        class="osk-dialog-wrapper"
      >
        <div class="osk-dialog-title osk-mb-xs">
          {{ $t('business.user.PointsActivateCodeDialog.title') }}
        </div>

        <div class="osk-activate-code-dialog__text osk-mb-mlg">
          {{ $t('business.user.PointsActivateCodeDialog.description') }}
        </div>

        <UikitForm
          ref="$form"
          class="osk-auth-enter-dialog__form"
          @submit-validate-success="submitSuccess"
          @submit-validate-error="handleSubmitError"
        >
          <UikitFormInput
            ref="$codeInput"
            name="code"
            :label="$t('business.user.PointsActivateCodeDialog.inputLabel')"
            rules="required"
          />

          <!-- <UikitFormErrorMessage name="globalError" /> -->

          <UikitButton
            class="osk-activate-code-dialog__button"
            full-width
            color="grey-6"
            :loading="pending"
          >
            {{ $t('business.user.PointsActivateCodeDialog.button') }}
          </UikitButton>
        </UikitForm>
      </div>
    </Transition>
  </UikitDialog>
</template>

<script setup lang="ts">
import type { TSubmitSuccess } from '~/types/components/form';
import { AnalyticsEvents } from '~/types/analytics';

import UikitForm from '~/components/uikit/form/Form.vue';
import UikitFormInput from '~/components/uikit/form/Input.vue';

const page = 'activate_code';

interface IForm {
  code: string;
  // globalError?: string
}

const model = defineModel<boolean>({ required: true });

const emits = defineEmits<{
  globalClose: []
}>();

const $form = ref<ComponentPublicInstance<typeof UikitForm>>();
const $codeInput = ref<ComponentPublicInstance<typeof UikitFormInput>>();

const showSuccess = ref(false);

const { $addEvent } = useNuxtApp();

async function sendAPIRequest(): Promise<{success: boolean}> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ success: true });
    }, 1000);
  });
}

const {
  data: sendAPIData,
  execute: sendAPI,
  pending,
  error,
} = useLazyAsyncData(() => sendAPIRequest(), { immediate: false });

pending.value = false;

const errorData = computed(() => error.value?.data?.errorData);

function handleClose() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    action: 'back',
  });
}

function handleGlobalClose() {
  model.value = false;
  emits('globalClose');

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    action: 'shopping',
  });
}

function handleSubmitError() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    action: 'activated',
    value: 'fail',
  });
}

async function submitSuccess({ values, actions }: TSubmitSuccess<IForm>) {
  // actions.setFieldError('globalError', undefined);

  // sendConfirmEvent();

  const res = await sendAPI();

  if (errorData.value || values.code === '666') {
    actions.setFieldError('code', 'Введенный подарочный код не существует. Пожалуйста, проверьте введенные цифры');

    $addEvent(AnalyticsEvents.ClickEvent, {
      page,
      action: 'activated',
      value: 'fail',
    });

    return;
  }

  if (sendAPIData.value?.success) {
    showSuccess.value = true;

    $addEvent(AnalyticsEvents.ClickEvent, {
      page,
      action: 'activated',
      value: 'success',
    });
  }
}

watch($codeInput, (input) => {
  if (input) {
    input.setFocus();
  }
});

watch(model, (value) => {
  if (!value) {
    showSuccess.value = false;
  } else {
    $addEvent(AnalyticsEvents.BlockView, { page });
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-activate-code-dialog {
  background: #000;

  .osk-dialog-wrapper {
    justify-content: center;

    @media screen and (max-height: 375px) {
      justify-content: flex-start;
      padding-top: 64px;
    }

    @include media-query(lg-and-up) {
      @media screen and (max-height: 450px) {
        justify-content: flex-start;
        padding-top: 94px;
        padding-bottom: 54px;
      }
    }
  }

  &__text {
    color: $grey-2;
    font-weight: 400;
  }

  &__button {
    margin-top: var(--indent-mlarge) !important;
  }

  &__success-button {
    margin-top: 10px;
  }
}
</style>
