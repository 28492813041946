<!-- eslint-disable vue/no-v-html -->
<template>
  <UikitDialog
    :model-value="modelValue"
    position="right"
    full-height
    full-height-no-scroll
    :no-shadow="!isDesktop"
    :hide-backdrop="!isDesktop"
    no-padding
    hide-close
    content-class="osk-global-dialog osk-global-dialog_second osk-auth-restore-dialog"
    @update:model-value="updateModelValue"
  >
    <UikitIcon
      name="ArrowBack"
      size="l"
      class="osk-dialog__close"
      @click="handleBack"
    />

    <a
      class="osk-global-dialog__phone"
      href="tel:+78007075308"
      @click="handlePhone"
    >
      <UikitIcon
        name="Phone"
        size="l"
      />
    </a>

    <template v-if="!isRestoreSuccess">
      <div class="osk-global-dialog__title">
        {{ $t('auth.restorePassword.title') }}
      </div>

      <p class="osk-global-dialog__p">
        {{ $t('auth.restorePassword.instruction') }}
      </p>

      <UikitForm
        class="osk-auth-restore-dialog__form"
        @submit-validate-success="submitSuccess"
        @submit-validate-error="sendConfirmEvent"
      >
        <UikitFormInput
          ref="$emailInput"
          v-model="emailModel"
          name="email"
          label="E-mail"
          rules="required|email"
        />

        <UikitButton
          class="osk-auth-restore-dialog__button"
          full-width
          :loading="loading"
        >
          {{ $t('common.continue') }}
        </UikitButton>
      </UikitForm>
    </template>

    <template v-else>
      <i18n-t
        keypath="auth.restorePassword.sendEmail"
        tag="div"
        class="osk-global-dialog__title"
      >
        <template #email>
          &nbsp;{{ emailModel }}
        </template>
      </i18n-t>

      <p class="osk-global-dialog__p">
        {{ $t('auth.restorePassword.spam') }}
      </p>

      <UikitButton
        full-width
        @click="globalClose"
      >
        {{ $t('auth.restorePassword.itsClear') }}
      </UikitButton>
    </template>
  </UikitDialog>
</template>

<script setup lang="ts">
import type { TSubmitSuccess } from '~/types/components/form';
import { AnalyticsEvents } from '~/types/analytics';
import { useApi } from '~/restAPI';
import { Account } from '~/restAPI/Account';

interface IForm {
  email: string;
  password: string;
}

const props = defineProps<{
  modelValue: boolean
  email?: string
}>();

const page = 'forgot_password';

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
  (e: 'success'): void,
  (e: 'globalClose'): void,
}>();

const $emailInput = ref();
const loading = ref(false);

const emailModel = ref('');

const { t } = useI18n();
const { $addEvent } = useNuxtApp();
const { createApiInstance } = useApi();
const { isDesktop } = useUiBreakpoints();
const { authDialogChapter } = useAccountStore();

const accountApi = createApiInstance(Account);

const {
  data: isRestoreSuccess,
  execute: resetPassword,
  error: resetPasswordError,
  pending: resetPasswordPending,
} = useLazyAsyncData(() => accountApi.resetPasswordUsingPost(
  {
    email: emailModel.value,
  },
).then((res) => res?.data?.data), { immediate: false });

resetPasswordPending.value = false;

const errorData = computed(() => resetPasswordError.value?.data?.errorData);

function globalClose() {
  emits('update:modelValue', false);
  emits('globalClose');
}

function sendConfirmEvent() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'send_email',
  });
}

async function submitSuccess({ values, actions }: TSubmitSuccess<IForm>) {
  loading.value = true;

  sendConfirmEvent();

  await resetPassword();

  loading.value = false;

  if (errorData.value) {
    Object.keys(errorData.value).forEach((name) => {
      actions.setFieldError(name as 'email', errorData.value[name]);
    });

    return;
  }

  if (resetPasswordError.value) {
    actions.setFieldError('email', t('common.error'));
  }
}

function handleBack() {
  emits('update:modelValue', false);

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'back',
  });
}

function handlePhone() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'contact_mobile',
  });
}

function updateModelValue(value: boolean) {
  emits('update:modelValue', value);
}

watch($emailInput, (input) => {
  if (input) {
    input.setFocus();
  }
});

watch(() => props.modelValue, async (value) => {
  if (value) {
    emailModel.value = props.email ?? '';

    $addEvent(AnalyticsEvents.BlockView, {
      page,
      chapter: authDialogChapter.value,
    });
  } else {
    loading.value = false;

    setTimeout(() => {
      isRestoreSuccess.value = null;
    }, 200);
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-auth-restore-dialog {
  .osk-global-dialog__p {
    margin-bottom: 32px;
  }

  &__button {
    margin-top: 32px !important;
  }

  &__bottom {
    text-align: center;
    margin-top: 24px;
  }

  &__restorePassword {
    font-weight: 600;
    cursor: pointer;

    @include media-query(lg-and-up) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
