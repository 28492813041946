import { RouteLocationNormalizedLoaded } from 'vue-router';
import type { CookieRef } from '#app';
import { TGenderPages, TGenderPagesValues } from '~/types/main';
import { PrimaryPageType } from '~/restAPI/data-contracts';

import type { TParentCategoryId } from '~/types/main';

const useDefaultCategory = ({
  gender,
  genderPages,
  getGenderCategoryByRoute,
}: {
  gender: CookieRef<TGenderPagesValues>,
  genderPages: TGenderPages,
  getGenderCategoryByRoute: () => 'women' | 'men' | 'kids' | 'lifestyle' | null
}): TGenderPagesValues => {
  const route = useRoute();

  if (route.name === 'index' && !gender.value) {
    return null;
  }

  const categoryByRoute = getGenderCategoryByRoute(route.fullPath);

  if (categoryByRoute) return categoryByRoute;

  if (gender.value) {
    return gender.value;
  }

  return genderPages[PrimaryPageType.FEMALE];
};

export const useActiveMenuCategory = () => {
  const {
    gender,
    genderPages,
    genderPagesIds,
    setGender,
    getGenderCategoryByRoute,
  } = useGender();

  const activeMenuCategory = useState<TGenderPagesValues>('activeMenuCategory', () => useDefaultCategory({ gender, genderPages, getGenderCategoryByRoute }));
  const stopChangingActiveMenu = useState('stopChangingActiveMenu', () => false);

  const activeMenuCategoryId = computed<TParentCategoryId | null>(() => activeMenuCategory.value && (genderPagesIds[activeMenuCategory.value] as TParentCategoryId));

  function changeActiveMenuCategory(category: TGenderPagesValues) {
    activeMenuCategory.value = category;
    setGender(activeMenuCategory.value);
  }

  function isActiveMenuCategory(category: TGenderPagesValues) {
    return category === activeMenuCategory.value;
  }

  function changeActiveMenuByRoute(fullPath: RouteLocationNormalizedLoaded['fullPath']) {
    const { isAuth, account } = useAccountStore();
    const categoryByRoute = getGenderCategoryByRoute(fullPath);

    if (categoryByRoute) {
      activeMenuCategory.value = categoryByRoute;
    } else if (isAuth.value) {
      if (account.value.sex === PrimaryPageType.MALE) {
        activeMenuCategory.value = genderPages[PrimaryPageType.MALE];
      }

      if (account.value.sex === PrimaryPageType.FEMALE) {
        activeMenuCategory.value = genderPages[PrimaryPageType.FEMALE];
      }
    }

    setGender(activeMenuCategory.value);
  }

  return {
    activeMenuCategory,
    activeMenuCategoryId,
    changeActiveMenuCategory,
    isActiveMenuCategory,
    changeActiveMenuByRoute,
    stopChangingActiveMenu,
  };
};
