<template>
  <UikitDialog
    v-model="model"
    position="right"
    full-height
    no-padding
    dark
    class="osk-points-dialog"
    @handle-close="handleClose"
  >
    <div class="osk-dialog-wrapper">
      <UikitLoaderInner v-if="getHistoryLoading || loading" />

      <div
        :class="{
          'osk-points-dialog__first-block': true,
          'osk-points-dialog__first-block__notFullVersion': !fullVersion,
          'osk-points-dialog__first-block__notAuth': !isAuth,
        }"
      >
        <template v-if="isAuth">
          <div class="osk-points-dialog__first-block-title">
            {{ numberFormat(userPoints) }}

            <span class="osk-points-dialog__first-block-title_text">
              {{ $t('business.user.points', userPoints ?? 0) }}
            </span>
          </div>

          <div
            v-if="burningPoints"
            class="osk-points-dialog__first-block-text1"
          >
            <UikitIcon
              name="Burn"
              size="s"
              class="osk-mr-xs"
              color="white"
            />
            {{ $t('business.user.pointsExpire', {count: burningPoints, text: numberFormat(burningPoints)}) }}
          </div>

          <div
            v-if="userPoints"
            class="osk-points-dialog__first-block-text2"
          >
            {{ $t('business.onePointOneRuble') }}
          </div>

          <div
            v-else
            class="osk-points-dialog__first-block-text2"
            style="max-width: 260px; margin: 0 auto;"
          >
            {{ $t('business.user.PointsDialog.noPoints') }}
          </div>

          <UikitButton
            v-if="fullVersion"
            class="osk-points-dialog__activate-button"
            full-width
            outline
            size="s"
            color="white"
            @click="handleActivateCode"
          >
            {{ $t('business.user.activateCode') }}
          </UikitButton>
        </template>

        <template v-else>
          <div class="osk-points-dialog__first-block-title">
            <span class="osk-points-dialog__first-block-title_text">
              {{ $t('business.user.PointsDialog.notAuth.title') }}
            </span>
          </div>

          <div
            class="osk-points-dialog__first-block-text2"
          >
            {{ $t('business.user.PointsDialog.notAuth.subtitle') }}
          </div>

          <UikitButton
            class="osk-points-dialog__activate-button"
            full-width
            outline
            size="m"
            color="white"
            @click="authUser"
          >
            {{ $t('business.user.PointsDialog.notAuth.auth') }}
          </UikitButton>
        </template>
      </div>

      <div
        :class="{
          'osk-points-dialog__content': true,
          'osk-points-dialog__content_notAuth': !isAuth,
        }"
      >
        <BusinessPointsHistory
          v-if="isAuth && pointsHistory?.items?.length"
          :history-list="pointsHistory.items"
          :event-points="eventValue"
          :page="page"
        />

        <div class="osk-dialog-title osk-mb-sm">
          {{ $t('business.user.PointsDialog.title1') }}
        </div>

        <div class="osk-points-dialog__text osk-mb-mlg">
          {{ $t('business.user.PointsDialog.text1') }}
        </div>

        <UikitDivider color="grey-5" />

        <div class="osk-dialog-title osk-mb-sm">
          {{ $t('business.user.PointsDialog.title2') }}
        </div>

        <div class="osk-points-dialog__text">
          {{ $t('business.user.PointsDialog.text2', { nbsp: '&nbsp;' }) }}
        </div>

        <br>

        <div class="osk-points-dialog__text osk-mb-mlg">
          {{ $t('business.user.PointsDialog.text2-2') }}
        </div>

        <UikitDivider
          v-if="fullVersion"
          color="grey-5"
        />

        <template v-if="fullVersion">
          <div class="osk-dialog-title osk-mb-sm">
            {{ $t('business.user.PointsDialog.title3') }}
          </div>

          <div class="osk-points-dialog__text osk-mb-lg">
            {{ $t('business.user.PointsDialog.text3') }}
          </div>

          <div class="osk-points-dialog__image">
            <img
              src="~assets/img/user/points-map.webp"
              alt="Points map"
              class="osk-points-dialog__map"
              @click="openMapDialog"
            >
          </div>

          <div class="osk-points-dialog__blocks">
            <div
              v-for="(boutique, index) in boutiquesData"
              :key="index"
              class="osk-points-dialog__block"
              @click="openBoutiqueDialog(boutique)"
            >
              <div class="osk-points-dialog__block-icon">
                <UikitIcon
                  name="GeoPoint"
                  color="white"
                />
              </div>

              <div class="osk-mb-lg">
                {{ boutique.address }}
              </div>

              <div class="osk-points-dialog__block-text-small">
                <span
                  v-for="(lot, idx) in boutique.lots"
                  :key="idx"
                  class="osk-points-dialog__boutique-lot"
                >
                  {{ lot }}
                </span>
              </div>
            </div>
          </div>

          <UikitButton
            full-width
            outline
            size="s"
            color="white"
            class="osk-mb-lg"
            @click="openMapDialog"
          >
            {{ $t('business.user.PointsDialog.showBoutiques') }}
          </UikitButton>

          <UikitDivider color="grey-5" />
        </template>

        <!-- TODO оставить на будущее -->
        <!-- <div class="osk-dialog-title osk-mb-sm">
          {{ $t('business.user.PointsDialog.title4') }}
        </div>

        <div class="osk-user-points-dialog__text osk-mb-lg">
          {{ $t('business.user.PointsDialog.text4') }}
        </div> -->

        <UikitButton
          full-width
          outline
          size="m"
          color="white"
          @click="handleAllProgramConditions"
        >
          {{ $t('business.user.PointsDialog.allProgramConditions') }}
        </UikitButton>
      </div>
    </div>

    <template
      v-if="fullVersion"
      #dialogs
    >
      <BusinessPointsActivateCodeDialog
        v-model="isActivateCodeDialog"
        @global-close="model = false"
      />

      <BusinessBoutiquesMapDialog
        v-model="isMapDialog"
        :boutiques="boutiquesData"
        @boutique-click="openBoutiqueDialog"
      />

      <BusinessBoutiquesBoutiqueDialog
        v-if="activeBoutique"
        v-model="isBoutiqueDialog"
        :boutique="activeBoutique"
      />
    </template>
  </UikitDialog>
</template>

<script setup lang="ts">
import type { IBoutique } from '@/composables/useBoutiques';

import { AnalyticsEvents } from '~/types/analytics';

import { OSKELLY_LOYALTY_PDF } from '~/data/consts';

import { isDefined } from '~/utils/extra';

const page = 'loyalty_program';

const model = defineModel<boolean>({ required: true });

const props = defineProps<{
  userPoints?: number | null
  burningPoints?: number | null
  loading?: boolean
}>();

const fullVersion = ref(false);

const isMapDialog = ref(false);
const isBoutiqueDialog = ref(false);
const isActivateCodeDialog = ref(false);

const activeBoutique = ref<IBoutique>();

const { isAuth, openAuthDialog } = useAccountStore();

const eventValue = computed(() => {
  const value = props.burningPoints || props.userPoints;

  return isDefined(value) ? String(value) : undefined;
});

const {
  getHistory, pointsHistory, getHistoryLoading,
} = usePoints();

const {
  boutiquesData,
  getBoutiques,
} = useBoutiques();

const { numberFormat } = useNumberFormatter();
const { $addEvent } = useNuxtApp();

function authUser() {
  openAuthDialog({ chapter: 'loyalty' });
}

function handleClose() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    value: eventValue.value,
    action: 'close',
  });
}

function handleActivateCode() {
  isActivateCodeDialog.value = true;

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    value: eventValue.value,
    action: 'activate_code',
  });
}

function openMapDialog() {
  isMapDialog.value = true;

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    value: eventValue.value,
    action: 'boutique_on_map',
  });
}

function handleAllProgramConditions() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    value: eventValue.value,
    action: 'all_conditions',
  });

  window.open(OSKELLY_LOYALTY_PDF, '_blank');
}

async function openBoutiqueDialog(boutique: IBoutique) {
  activeBoutique.value = boutique;
  await nextTick();

  isBoutiqueDialog.value = true;

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    value: eventValue.value,
    action: 'boutique_selection',
    id_item: boutique.id,
  });
}

watch(model, (value) => {
  if (value) {
    if (isAuth.value) {
      getHistory();
    }

    $addEvent(AnalyticsEvents.BlockView, {
      page,
      value: eventValue.value,
    });
  }
});

onMounted(() => {
  getBoutiques();
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-points-dialog {
  z-index: 1010 !important;

  .osk-dialog-wrapper {
    padding: 0;
  }

  &__first-block {
    position: relative;
    background: url(~/assets/img/user/user-points.webp) center top no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;

    &__notFullVersion {
      padding: 218px var(--indent-medium) 52px;

      @include media-query(lg-and-up) {
        padding: 238px 52px 52px;
      }
    }

    &__notAuth {
      height: 375px;
      padding: 0 16px 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex-shrink: 0;

      @include media-query(lg-and-up) {
        height: 394px;
        padding: 0 52px 24px;
      }

      .osk-points-dialog__first-block-title_text {
        @include media-query(lg-and-up) {
          @include font-style(52px, 49.4px, 400);
          margin-bottom: var(--indent-compact);
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(360deg, #111111 0%, rgba(17, 17, 17, 0.768225) 0.07%, rgba(17, 17, 17, 0.668116) 39.57%,
      rgba(17, 17, 17, 0.557309) 46.17%, rgba(17, 17, 17, 0.442691) 52.77%, rgba(17, 17, 17, 0.331884) 59.36%,
      rgba(17, 17, 17, 0.231775) 65.96%, rgba(17, 17, 17, 0.147411) 72.55%, rgba(17, 17, 17, 0.0816599) 79.15%,
      rgba(17, 17, 17, 0.03551) 85.74%, rgba(17, 17, 17, 0.0086472) 92.34%, rgba(17, 17, 17, 0) 98.94%);
    }

    .osk-points-dialog__activate-button {
      margin-top: var(--indent-mlarge);
    }
  }

  &__first-block-title {
    @include font-style(32px, 30.4px, 400);

    font-family: 'Playfair Display', serif;
    font-style: italic;
    margin-bottom: var(--indent-medium);
    z-index: 1;
    position: relative;
  }

  &__first-block-text1 {
    z-index: 1;
    position: relative;
    margin-bottom: var(--indent-compact);
  }

  &__first-block-text2 {
    z-index: 1;
    position: relative;

  }

  &__activate-button {
    margin-top: var(--indent-mlarge);

    @include media-query(lg-and-up) {
      margin-top: var(--indent-large);
    }
  }

  &__content {
    padding: var(--indent-large) var(--indent-medium) 42px;

    @include media-query(lg-and-up) {
      padding: var(--indent-mlarge) 52px 52px;
    }

    &_notAuth {
      padding-top: var(--indent-compact);

      @include media-query(lg-and-up) {
        padding-top: var(--indent-mlarge);
      }
    }
  }

  &__text {
    line-height: var(--line-height-subheading-2);
  }

  &__image {
    overflow: hidden;
    margin: 0 -16px var(--indent-medium);

    @include media-query(lg-and-up) {
      margin-left: 0;
      margin-right: 0;
      border-radius: $radius-small;
    }
  }

  &__map {
    display: block;
    width: 100%;
    cursor: pointer;
  }

  &__blocks {
    display: flex;
    margin-bottom: var(--indent-large);
  }

  &__block {
    border-radius: $radius-small;
    background: rgba(255, 255, 255, 0.3);
    width: 50%;
    padding: 16px 16px 20px;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__block-icon {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background: #686868;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--indent-mmedium);
  }

  &__block-text-small {
    @include font-style(var(--font-size-caption), var(--line-height-caption), var(--font-weight-medium));
    color: var(--grey-3);
  }

  &__boutique-lot {
    & + &:before {
      content: '•';
      margin: 0 4px;
    }
  }
}
</style>
