<template>
  <UikitDialog
    :model-value="modelValue"
    position="right"
    full-height
    full-height-no-scroll
    :no-shadow="!isDesktop"
    :hide-backdrop="!isDesktop"
    no-padding
    hide-close
    content-class="osk-global-dialog osk-global-dialog_second osk-auth-enter-dialog"
    @update:model-value="updateModelValue"
  >
    <UikitIcon
      name="ArrowBack"
      size="l"
      class="osk-dialog__close"
      @click="handleBack"
    />

    <a
      class="osk-global-dialog__phone"
      href="tel:+78007075308"
      @click="handlePhone"
    >
      <UikitIcon
        name="Phone"
        size="l"
      />
    </a>

    <div class="osk-global-dialog__title">
      {{ $t('auth.enterPassword') }}
    </div>

    <p class="osk-global-dialog__p">
      {{ $t('auth.enterPassword.fromAccount') }} @{{ user?.nickname }}
    </p>

    <UikitForm
      ref="$form"
      class="osk-auth-enter-dialog__form"
      @submit-validate-success="submitSuccess"
      @submit-validate-error="handleSubmitError"
    >
      <UikitFormInput
        ref="$emailInput"
        name="password"
        type="password"
        :label="$t('common.auth.password')"
        rules="required"
        autocomplete="on"
      />

      <UikitFormErrorMessage name="globalError" />

      <UikitButton
        class="osk-auth-enter-dialog__button"
        full-width
        :loading="loading"
      >
        {{ $t('common.continue') }}
      </UikitButton>

      <div class="osk-auth-login-dialog__bottom">
        <span
          class="osk-auth-login-dialog__restorePassword"
          @click="restorePassword"
        >
          {{ $t('auth.enterPassword.doNotRememberPassword') }}
        </span>
      </div>
    </UikitForm>

    <template #dialogs>
      <AuthRestorePasswordDialog
        v-model="authRestorePasswordDialog"
        :email="user?.email"
        :z-index="1102"
        @global-close="globalClose"
      />
    </template>
  </UikitDialog>
</template>

<script setup lang="ts">
import type { TSubmitSuccess } from '~/types/components/form';
import { AnalyticsEvents } from '~/types/analytics';

import { useAccountService } from '~/providers/account';

import type { ISavedAccount } from '@/composables/store/useAccountStore';

interface IForm {
  password: string;
  globalError?: string
}

const props = defineProps<{
  modelValue: boolean
  user: ISavedAccount | null
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
  (e: 'success'): void,
  (e: 'globalClose'): void,
}>();

const page = 'enter_password';

const authRestorePasswordDialog = ref(false);
const $emailInput = ref();
const loading = ref(false);
const $form = ref();

const { isDesktop } = useUiBreakpoints();
const route = useRoute();
const { getGlobalSettings } = useGlobalSettings(route);
const { auth } = useAccountService();
const { $addEvent } = useNuxtApp();
const { authDialogChapter } = useAccountStore();

function restorePassword() {
  authRestorePasswordDialog.value = true;

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'forgot_password',
  });
}

function globalClose() {
  emits('update:modelValue', false);
  emits('globalClose');
}

function handleSubmitError() {
  $form.value.setFieldError('globalError', undefined);

  sendConfirmEvent();
}

function sendConfirmEvent() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'confirm',
  });
}

async function submitSuccess({ values, actions }: TSubmitSuccess<IForm>) {
  actions.setFieldError('globalError', undefined);

  if (!props.user?.email) return;

  loading.value = true;

  sendConfirmEvent();

  try {
    await auth(props.user.email, values.password);
    await getGlobalSettings();

    $addEvent(AnalyticsEvents.EnterLogin, {
      page,
      chapter: authDialogChapter.value,
    });
  } catch (error) {
    if (error.data?.message) {
      actions.setFieldError('globalError', error.data.message);
    }

    loading.value = false;
    console.error(error);
  }
}

function handleBack() {
  emits('update:modelValue', false);

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'back',
  });
}

function handlePhone() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'contact_mobile',
  });
}

function updateModelValue(value: boolean) {
  emits('update:modelValue', value);
}

watch($emailInput, (input) => {
  if (input) {
    input.setFocus();
  }
});

watch(() => props.modelValue, async (value) => {
  if (value) {
    $addEvent(AnalyticsEvents.BlockView, {
      page,
      chapter: authDialogChapter.value,
    });
  } else {
    loading.value = false;
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-auth-enter-dialog {
  .osk-global-dialog__p {
    margin-bottom: 32px;
  }

  &__button {
    margin-top: 32px !important;
  }

  &__bottom {
    text-align: center;
    margin-top: 24px;
  }

  &__restorePassword {
    font-weight: 600;
    cursor: pointer;

    @include media-query(lg-and-up) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
