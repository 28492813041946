<template>
  <div>
    <HomePageTop />
    <HomeDownloadAppDesktop
      v-if="$device.isDesktop"
      class="osk-mt-xxxl"
    >
      <template #qr-code>
        <img
          src="~assets/img/webToApp/qr_main_gender.webp"
          alt="qr_code"
          height="134px"
          width="134px"
        >
      </template>
    </HomeDownloadAppDesktop>
    <HomeServiceWork />
    <HomeCommunity />
    <HomeBenefits />
    <HomeCelebrity />
    <BusinessProductsOskellyChoice />
    <HomeDownloadAppMobile v-if="$device.isMobile" />

    <OCommunityDialog
      v-model="isCommunityDialogOpen"
      :owner-mode="communityDialogOwnerMode"
      :user="communityDialogOwnerMode && isAuth ? account : undefined"
    />

    <WebToAppDownloadAppDesktopDialog
      v-if="$device.isDesktop"
      v-model="isWebToAppDownloadAppDesktopDialogOpen"
    >
      <template #qr-code>
        <img
          src="~assets/img/webToApp/qr_main_timeout.webp"
          height="124"
          width="124"
          alt="qr_code"
        >
      </template>
    </WebToAppDownloadAppDesktopDialog>

    <template v-if="route.meta.loyalty">
      <BusinessPointsDialog
        v-model="isPointsDialog"
        :user-points="userPointsComp"
        :burning-points="burningPointsComp"
        :loading="getBalanceBriefLoading"
      />

      <BusinessPointsJoinProgramDialog
        v-model="isPointsJoinDialog"
        @agree="pointsAgree"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { LS_KEY_WEB_TO_APP_DOWNLOAD_APP_DESKTOP_DIALOG, LS_KEY_WEB_TO_APP_GENDER_DIALOG } from 'assets/constants';
import OCommunityDialog from '~/components/account/OCommunityDialog.vue';
import useWebToApp from '~/composables/useWebToApp';

definePageMeta({
  middleware: 'gender',
});

const isPointsDialog = ref(false);
const isPointsJoinDialog = ref(false);

const { t } = useI18n();
const schemaOrg = useSchemaOrg();

const {
  getBalanceBrief,
  burningPointsComp,
  userPointsComp,
  getBalanceBriefLoading,
} = usePoints();

useHead({
  bodyAttrs: {
    class: 'oskelly-2_0',
  },
  title: `${t('pages.index.base.title')} – ${t('common.OskellyOnlineStore')}`,
  meta: [
    {
      name: 'description',
      content: `${t('pages.index.base.description')}`,
    },
  ],
  script: [
    {
      type: 'application/ld+json',
      children: schemaOrg,
    },
  ],
});

const { account, isAuth } = useAccountStore();
const route = useRoute();
const showWebToAppDownloadAppDesktopDialog = useLocalStorage(LS_KEY_WEB_TO_APP_DOWNLOAD_APP_DESKTOP_DIALOG, true);
const isCommunityDialogOpen = ref(false);
const isWebToAppDownloadAppDesktopDialogOpen = ref(false);
const communityDialogOwnerMode = ref(false);
let showWebToAppDownloadAppDesktopDialogTimeoutId = 0;

async function pointsAgree() {
  await nextTick();

  isPointsDialog.value = true;
}

async function openLoyaltyDialog() {
  if (!isAuth.value) {
    isPointsDialog.value = true;

    return;
  }

  getBalanceBrief();

  if (!account.value.isLoyaltyProgramAccepted) {
    isPointsJoinDialog.value = true;
    return;
  }

  isPointsDialog.value = true;
}

onMounted(() => {
  if (showWebToAppDownloadAppDesktopDialog.value) {
    showWebToAppDownloadAppDesktopDialogTimeoutId = window.setTimeout(() => {
      isWebToAppDownloadAppDesktopDialogOpen.value = true;
      showWebToAppDownloadAppDesktopDialog.value = false;
    }, 90000);
  }

  if (route.meta.loyalty) {
    openLoyaltyDialog();
    return;
  }

  if (!isDefined(route.meta.communityDialog)) return;

  communityDialogOwnerMode.value = isDefined(route.meta.communityDialog.ownerMode)
    ? route.meta.communityDialog.ownerMode
    : Boolean(isAuth.value && account.value.communityBadge?.status?.code);

  isCommunityDialogOpen.value = true;
});

onUnmounted(() => clearTimeout(showWebToAppDownloadAppDesktopDialogTimeoutId));
</script>
