<template>
  <UikitDialog
    v-model="model"
    position="right"
    full-height
    no-padding
    dark
    content-class="osk-boutiques-images-dialog"
  >
    <div class="osk-dialog-wrapper">
      <UikitSwiper
        v-if="images?.length"
        off
        navigation
        pagination
        arrow-back
        :initial-slide="activeImageIndex"
        :breakpoints="sliderBreakpoints"
        class="osk-boutiques-images-dialog__swiper"
      >
        <UikitSwiperSlide
          v-for="(image, index) in images"
          :key="index"
          class="osk-boutiques-images-dialog__item"
        >
          <vue-load-image
            class="osk-boutiques-images-dialog__image-block"
          >
            <template #image>
              <img
                :src="image"
                alt="boutique"
                class="osk-boutiques-images-dialog__image"
              >
            </template>

            <template #preloader>
              <UikitLoaderInner />
            </template>
          </vue-load-image>
        </UikitSwiperSlide>
      </UikitSwiper>
    </div>
  </UikitDialog>
</template>

<script setup lang="ts">
import VueLoadImage from 'vue-load-image';

const sliderBreakpoints = {
  0: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 16,
    navigation: { enabled: false },
    pagination: { enabled: true },
  },
  1024: {
    navigation: { enabled: true },
  },
};

const model = defineModel<boolean>({ required: true });

defineProps<{
  images: string[]
  activeImageIndex: number
}>();
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-boutiques-images-dialog {
  .osk-dialog-wrapper {
    background: #a99483 !important;
    justify-content: center;
    padding: 0;
  }

  .osk-swiper-button-prev,
  .osk-swiper-button-next {
    top: 42% !important;
    background: rgba(255, 255, 255, .7);
    width: 32px;
    height: 32px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    transition: all linear .2s;

    &:hover {
      opacity: .7;
    }

    &.swiper-button-disabled {
      opacity: .5;
    }
  }

  &__swiper {
    height: 100%;
    max-height: 404px;

    @include media-query(lg-and-up) {
      max-height: 588px;
    }

    .osk-swiper-button-prev {
      left: 24px !important;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .osk-swiper-button-next {
      right: 24px !important;
      box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .osk-swiper__block {
      height: 100%;

      .swiper-pagination {
        bottom: 17px;
      }

      .swiper-pagination-bullet {
        background: #cbbfb5;
        margin: 0 2px !important;
        transition: all linear .2s;
        position: relative;

        &:after {
          content: '';
          width: 0;
          height: 7px;
          background: #fff;
          border-radius: 50px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          transition: all linear .2s;
        }

        &.swiper-pagination-bullet-active {
          width: 24px;
          border-radius: 50px;
          position: relative;

          &:after {
            width: 24px;
            height: 100%;
          }
        }
      }
    }
  }

  &__image-block {
    width: 100%;
    height: calc(100% - 40px);
    position: relative;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
