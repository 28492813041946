<template>
  <UikitDialog
    v-model="model"
    full-height
    no-padding
    position="right"
    dark
    class="osk-points-qr-dialog"
  >
    <div class="osk-dialog-wrapper">
      <div class="osk-points-qr-dialog__block">
        <div class="osk-points-qr-dialog__block-title">
          {{ $t('business.user.PointsHistory.dialog.title') }}
        </div>

        <div class="osk-points-qr-dialog__block-text">
          {{ $t('business.user.PointsHistory.dialog.text') }}
        </div>

        <UikitButton
          v-if="isMobile && isApple"
          :href="apps.appStoreRU"
          target="_blank"
          outline
          size="s"
          full-width
          color="white"
          icon="AppStoreButton"
        />

        <UikitButton
          v-if="isMobile && isAndroid"
          :href="apps.playMarket"
          target="_blank"
          outline
          size="s"
          full-width
          color="white"
          icon="GooglePlayButton"
        />

        <img
          v-if="!isMobile"
          src="~assets/img/user/qr-app.webp"
          alt="QR code"
          class="osk-points-qr-dialog__qr"
        >
      </div>
    </div>
  </UikitDialog>
</template>

<script setup lang="ts">
const model = defineModel<boolean>({ required: true });

const { apps } = useCompanyInfo();
const { isMobile } = useUiBreakpoints();
const { isApple, isAndroid } = useDevice();
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-points-qr-dialog {
  z-index: 1011 !important;

  .osk-dialog-wrapper {
    justify-content: center;
    align-items: flex-start;

    @media screen and (max-height: 375px) {
      justify-content: flex-start;
      padding-top: 64px;
    }

    @include media-query(lg-and-up) {
      @media screen and (max-height: 470px) {
        justify-content: flex-start;
        padding-top: 48px;
      }
    }
  }

  &__block-title {
    @include font-style(var(--font-size-heading), var(--line-height-heading), var(--font-weight-bold));
    margin-bottom: var(--indent-mlarge);
  }

  &__block-text {
    margin-bottom: 42px;

    @include media-query(lg-and-up) {
      margin-bottom: 49px;
    }
  }

  &__qr {
    display: block;
    width: 175px;
    height: 175px;
    flex-shrink: 0;
  }
}
</style>
