<template>
  <div :class="classes">
    <div
      v-if="label"
      class="osk-field__label"
      :title="label"
    >
      {{ label }}
      <span v-if="required && !hideStar">*</span>
    </div>

    <div
      :class="{
        'osk-field__inner': true,
        'osk-field__inner_border-bottom': borderBottom,
        'osk-field__inner_disabled': disabled,
      }"
      @click="handleClick"
    >
      <div
        v-if="slots.prepend"
        class="osk-field__prepend"
      >
        <slot name="prepend" />
      </div>

      <div
        v-if="iconLeft || textLeft"
        class="osk-field__icon-left"
      >
        <span
          v-if="textLeft"
          class="osk-field__textLeft"
        >
          {{ textLeft }}
        </span>

        <UikitIcon
          v-if="iconLeft"
          :name="iconLeft"
          size="m"
          :color="background === 'grey-4' ? 'grey' : undefined"
          class="osk-field__icon-left-icon"
        />
      </div>

      <slot />

      <div
        v-if="slots.append"
        class="osk-field__append"
      >
        <slot name="append" />
      </div>

      <UikitIcon
        v-if="iconRight"
        :name="iconRight"
        class="osk-field__icon-right"
      />
    </div>

    <slot name="message">
      <UikitFormErrorMessage
        v-if="!noErrorMessage"
        :name="name"
        :message="errorText"
      />
    </slot>
  </div>
</template>

<script setup lang="ts">
import { RuleExpression, useField } from 'vee-validate';
import type { TIconNames } from '~/assets/icons';
import type { TTextField } from '~/types/components/form';

const props = withDefaults(defineProps<{
  modelValue?: any,
  name: string,
  label?: string,
  errorText?: string,
  rules?: RuleExpression<string | number | number[] | boolean | null>,
  required?: boolean,
  borderBottom?: boolean,
  noErrorMessage?: boolean,
  iconLeft?: TIconNames
  iconRight?: TIconNames
  textLeft?: string
  disabled?: boolean
  round?: boolean
  hideStar?: boolean
  outline?: boolean
  background?: 'grey-4'
}>(), {
  modelValue: undefined,
  label: undefined,
  rules: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  textLeft: undefined,
  errorText: undefined,
  background: undefined,
});

const slots = useSlots();

const rulesComp = computed(() => {
  if (!props.required) return props.rules;

  if (!props.rules || (typeof props.rules === 'string' && !props.rules.includes('required'))) {
    return `required|${props.rules ?? ''}`;
  }

  return props.rules;
});

const {
  errorMessage, value, handleChange, setValue,
} = useField(toRef(props, 'name'), rulesComp, {
  initialValue: props.modelValue ?? '',
  validateOnValueUpdate: false,
  syncVModel: true,
});

const classes = computed(() => ({
  'osk-field': true,
  'osk-field_error': !!errorMessage.value,
  'osk-field_round': !!props.round,
  'osk-field_outline': props.outline,
  [`osk-field_bg-${props.background}`]: !!props.background,
}));

function handleClick(e: Event) {
  const noFocus = (e.target as HTMLElement)?.closest('.osk-input_noFocus');

  if (e.currentTarget instanceof Element && !noFocus) {
    const textField: TTextField | null = e.currentTarget.querySelector('input, textarea');

    if (textField && document.activeElement !== textField) textField?.focus();
  }
}

defineExpose({
  value,
  errorMessage,
  handleChange,
  setValue,
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-field {
  display: flex;
  flex-direction: column;

  & + &,
  & + .osk-field-row,
  & + .osk-button {
    margin-top: $indent-compact;
  }

  &__textLeft {
    color: $grey;
  }

  &__icon-left {
    display: flex;
    align-items: center;
    margin-left: -3px;
    margin-right: 8px;
  }

  &_round {
    border-radius: 100px !important;
    overflow: hidden;
  }

  &_outline &__inner {
    border-color: transparent;
  }

  &_bg-grey-4 {
    background: $grey-4;
    border-radius: 2px;
    overflow: hidden;

    .osk-field__inner {
      border: 1px solid $grey-4;
    }
  }

  &__label {
    @include font-style($font-size-caption, $line-height-caption, $font-weight-semibold);
    @include overflow-ellipsis;
    color: $grey;
    display: flex;
    margin-bottom: $indent-small;
  }

  &__inner {
    border: 1px solid $grey-3;
    border-radius: $radius-small;
    display: flex;
    padding: 0 $indent-compact;
    transition: all linear .2s;
    position: relative;

    &_border-bottom {
      border: 0;
      border-bottom: 1px solid $black;
      padding: 0;
    }

    &_disabled {
      background-color: $grey-4;
      color: $grey;

      .osk-input__input_disabled {
        color: $grey;
        opacity: 1;
      }
    }
  }

  &__inner_border-bottom {
    .osk-field__append {
      padding: 0;
      margin-right: 0;
    }

    .osk-field__prepend {
      padding: 0;
      margin-left: 0;
    }
  }

  &__append {
    display: flex;
    margin-right: -12px;
  }

  &__prepend {
    display: flex;
    margin-left: -12px;
  }
}
</style>
