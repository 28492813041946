<template>
  <UikitDialog
    v-model="model"
    position="right"
    full-height
    no-padding
    dark
    content-class="osk-boutique-dialog"
  >
    <div class="osk-dialog-wrapper">
      <vue-load-image
        class="osk-boutique-dialog__image-block"
      >
        <template #image>
          <img
            :src="boutique.images[0]"
            :alt="boutique.title"
            class="osk-boutique-dialog__image"
          >
        </template>

        <template #preloader>
          <UikitLoaderInner />
        </template>
      </vue-load-image>

      <div class="osk-boutique-dialog__content">
        <div class="osk-dialog-title">
          {{ $t('business.boutiques.BoutiqueDialog.address') }}
        </div>

        <div class="osk-boutique-dialog__address">
          <span>{{ boutique.address }}</span>

          <div class="osk-boutique-dialog__address-icon">
            <UikitIcon
              name="GeoPoint"
              color="white"
            />
          </div>
        </div>

        <div class="osk-boutique-dialog__lots osk-mb-mlg">
          <span
            v-for="(lot, idx) in boutique.lots"
            :key="idx"
            class="osk-boutique-dialog__lot"
          >
            {{ lot }}
          </span>
        </div>

        <UikitDivider color="grey-5" />

        <template v-if="boutique.description">
          <div class="osk-dialog-title">
            {{ $t('business.boutiques.BoutiqueDialog.about') }}
          </div>

          <div class="osk-boutique-dialog__text osk-mb-mlg">
            {{ boutique.description }}
          </div>

          <UikitDivider color="grey-5" />
        </template>

        <template v-if="boutique.openingHours">
          <div class="osk-dialog-title">
            {{ $t('business.boutiques.BoutiqueDialog.openingHours') }}
          </div>

          <div
            v-for="(date, index) in boutique.openingHours"
            :key="index"
            class="osk-boutique-dialog__openingHours"
          >
            <span class="osk-boutique-dialog__openingHours-text">
              {{ date.date }}
            </span>

            <span class="osk-boutique-dialog__openingHours-text">
              {{ date.time }}
            </span>
          </div>

          <UikitDivider
            color="grey-5"
            class="osk-mt-mlg"
          />
        </template>

        <div class="osk-dialog-title">
          {{ $t('business.boutiques.BoutiqueDialog.photos') }}
        </div>

        <ul class="osk-boutique-dialog__image-list">
          <li
            v-for="(image, index) in boutique.images"
            :key="index"
            class="osk-boutique-dialog__image-list-item"
          >
            <vue-load-image
              class="osk-boutique-dialog__image-list-block"
            >
              <template #image>
                <img
                  :src="image"
                  alt="boutique"
                  class="osk-boutique-dialog__image-list-img"
                  @click="openImagesDialog(index)"
                >
              </template>

              <template #preloader>
                <UikitLoaderInner small />
              </template>
            </vue-load-image>
          </li>
        </ul>

        <template v-if="boutique.contacts">
          <div class="osk-dialog-title">
            {{ $t('business.boutiques.BoutiqueDialog.contacts') }}
          </div>

          <ul>
            <li
              v-for="(contact, index) in boutique.contacts"
              :key="index"
              class="osk-boutique-dialog__contact-item"
            >
              <a
                :href="contact.href"
                target="_blank"
                class="osk-boutique-dialog__contact-link"
              >
                <UikitIcon
                  :name="getContactIcon(contact.type)"
                  color="white"
                />
                {{ contact.text }}
              </a>
            </li>
          </ul>
        </template>
      </div>
    </div>

    <BusinessBoutiquesImagesDialog
      v-model="isImagesDialogOpen"
      :images="boutique.images"
      :active-image-index="activeImageIndex"
    />
  </UikitDialog>
</template>

<script setup lang="ts">
import VueLoadImage from 'vue-load-image';

import type { IBoutique } from '@/composables/useBoutiques';

const model = defineModel<boolean>({ required: true });

defineProps<{
  boutique: IBoutique
}>();

const isImagesDialogOpen = ref(false);
const activeImageIndex = ref(0);

function openImagesDialog(index: number) {
  activeImageIndex.value = index;
  isImagesDialogOpen.value = true;
}

function getContactIcon(type: string) {
  if (type === 'site') return 'Planet';
  if (type === 'phone') return 'Phone';

  return 'Sobachka';
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-boutique-dialog {
  .osk-dialog-wrapper {
    padding: 0;
  }

  &__image-block {
    height: 177px;
    display: block;
    position: relative;
    flex-shrink: 0;

    @include media-query(lg-and-up) {
      height: 394px;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    padding: var(--indent-compact) var(--indent-medium) 42px;

    @include media-query(lg-and-up) {
      padding: var(--indent-mlarge) 52px 52px;
    }
  }

  &__loader {
    background: var(--grey-4);
  }

  &__text {
    line-height: var(--line-height-subheading-2);
  }

  &__address {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--indent-medium) 0;
  }

  &__address-icon {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: var(--indent-mlarge);
  }

  &__lots {
    padding: var(--indent-small) 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__lot {
    @include font-style(var(--font-size-caption), 16px, 400);
    background: rgba(255, 255, 255, 0.15);
    padding: 10px var(--indent-compact);
    border-radius: 100px;
    margin-right: var(--indent-compact);
  }

  &__openingHours {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 11px;

    &:last-child {
      margin-bottom: var(--indent-mlarge);
    }
  }

  &__contact-item {
    padding: var(--indent-medium) 0;
  }

  &__contact-link {
    display: inline-flex;
    align-items: center;

    .osk-icon {
      margin-right: var(--indent-medium);
    }
  }

  &__image-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 103px;
    gap: 16px;
    margin-bottom: 47px;
    margin-top: 28px;

    @include media-query(lg-and-up) {
      grid-auto-rows: 122px;
    }
  }

  &__image-list-block {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: $radius-small;
    overflow: hidden;
  }

  &__image-list-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}
</style>
