<template>
  <UikitDialog
    :model-value="modelValue"
    position="right"
    full-height
    full-height-no-scroll
    :no-shadow="!isDesktop"
    :hide-backdrop="!isDesktop"
    no-padding
    :z-index="1100"
    :content-class="`osk-phone-verify-dialog ${phoneVerifyDialogSettings?.secondDialog ? 'osk-phone-verify-dialog_second' : ''}`"
    @update:model-value="updateModelValue"
  >
    <WebToAppDownloadAppMobileTop
      v-if="$device.isMobile"
      app-link="https://oskelly.onelink.me/kabM/wgktazb3"
    />

    <WebToAppDownloadAppMobileTop
      v-else
      :promo-text="$t('auth.phoneVerifyDialog.webToApp.promoText', { sale })"
      :subtitle="$t('auth.phoneVerifyDialog.webToApp.subtitle')"
      :with-btn="false"
    >
      <template #qr-code>
        <img
          src="~assets/img/webToApp/qr_registration_login.webp"
          alt="qr_code"
          height="76px"
          width="76px"
        >
      </template>
    </WebToAppDownloadAppMobileTop>

    <a
      class="osk-phone-verify-dialog__phone"
      href="tel:+78007075308"
      @click="handlePhone"
    >
      <UikitIcon
        name="Phone"
        size="l"
      />
    </a>

    <template v-if="phoneVerifyChapter === 'auth'">
      <div class="osk-phone-verify-dialog__title osk-phone-verify-dialog__title_auth">
        {{ $t('auth.enterPhoneNumber') }}
      </div>

      <p class="osk-phone-verify-dialog__p">
        {{ $t('auth.allowQuickly') }}
      </p>

      <p class="osk-phone-verify-dialog__p">
        {{ $t('auth.weWillSendSMS') }}
      </p>
    </template>

    <template v-else>
      <div class="osk-phone-verify-dialog__title">
        {{ phoneVerifyDialogSettings?.phoneTitle ?? $t('auth.leavePhoneNumber') }}
      </div>

      <p
        v-if="phoneVerifyDialogSettings?.phoneText"
        class="osk-phone-verify-dialog__p"
      >
        {{ phoneVerifyDialogSettings.phoneText }}
      </p>
    </template>

    <UikitForm
      class="osk-phone-verify-dialog__form"
      @submit-validate-success="submitSuccess"
    >
      <UikitFormPhoneInput
        ref="$phoneInput"
        v-model="phoneNumber"
        name="phoneNumber"
        :label="$t('auth.phoneNumberLabel')"
        :only-countries="onlyCountries"
        required
        rules="phone2"
        hide-star
      />

      <WidgetCloudflareTurnstile v-if="isVisibleWidgetCloudflareTurnstile" />

      <UikitFormErrorMessage name="globalError" />

      <UikitButton
        class="osk-phone-verify-dialog__button"
        full-width
        :loading="loading"
        :disabled="!turnstileToken"
      >
        {{ $t('auth.getCode') }}
      </UikitButton>
    </UikitForm>

    <template #dialogs>
      <AuthSmsDialog
        v-model="authSmsDialog"
        :formatted-phone="formattedPhone"
        :phone="phoneNumber"
        :token="verificationToken"
        :loading="generateCodePending"
        :error="generateCodeError?.data?.message"
        :z-index="1101"
        @resend="resendSmsCode"
        @close="emits('update:modelValue', false)"
      />
    </template>
  </UikitDialog>
</template>

<script setup lang="ts">
import { AnalyticsEvents } from '~/types/analytics';

const props = defineProps<{
  modelValue: boolean
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
  (e: 'success'): void,
}>();

const { sale } = useWebToApp();
const { isDesktop } = useUiBreakpoints();
const {
  phoneVerifyDialogSettings,
  phoneVerifyChapter,
  authSmsDialog,
  phoneNumber,
  loading,
  $phoneInput,
  onlyCountries,
  formattedPhone,
  operation,
  verificationToken,
  generateCodePending,
  generateCodeError,
  getCountries,
  resendSmsCode,
  submitSuccess,
} = usePhoneVerification();
const { $addEvent } = useNuxtApp();
const { query } = useRoute();
const { turnstileToken, isVisibleWidgetContainer } = useWidgetCloudflareTurnstile();

const isVisibleWidgetCloudflareTurnstile = computed(() => props.modelValue && !authSmsDialog.value && isVisibleWidgetContainer.value);

function handlePhone() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page: 'phone_number_input',
    chapter: phoneVerifyChapter.value,
    action: 'contact_mobile',
  });
}

function updateModelValue(value: boolean) {
  emits('update:modelValue', value);

  if (!value) {
    $addEvent(AnalyticsEvents.ClickEvent, {
      page: 'phone_number_input',
      chapter: phoneVerifyChapter.value,
      action: 'close',
    });
  }
}

watch(() => props.modelValue, async (value) => {
  if (value) {
    operation.value = 'VERIFY_PHONE_NUMBER';

    $addEvent(AnalyticsEvents.BlockView, {
      page: 'phone_number_input',
      chapter: phoneVerifyChapter.value,
    });

    await getCountries(query.countryCode);

    if ($phoneInput.value) {
      $phoneInput.value.setFocus();
    }
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-phone-verify-dialog {
  .osk-web-to-app-download-app-mobile-top {
    position: absolute!important;
    top: 48px;
    left: 16px;
    right: 16px;
    z-index: 999!important;

    @include media-query(lg-and-up) {
      top: 109px;
      left: 52px;
      right: 52px;
    }
  }

  &.osk-dialog__body {
    @include font-body-all;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    justify-content: center;
    font-family: $font-family-default;
    padding: 0 16px;

    @media screen and (max-height: 450px) {
      justify-content: flex-start;
      padding-top: 54px;
      padding-bottom: 16px;
    }

    @include media-query(lg-and-up) {
      width: 504px;
      padding: 0 52px;

      @media screen and (max-height: 450px) {
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;
      }

      @media screen and (max-height: 500px) {
        justify-content: flex-start;
        padding-top: 94px;
        padding-bottom: 54px;
      }
    }
  }

  &_second + .osk-dialog__backdrop {
    width: calc(100% - 504px);
  }

  .osk-dialog__close {
    right: auto;
    left: 12px;
    top: 16px;

    @include media-query(lg-and-up) {
      right: auto;
      left: 48px;
      top: 44px;
    }
  }

  &__phone {
    position: absolute;
    cursor: pointer;
    right: 16px;
    top: 16px;

    @include media-query(lg-and-up) {
      right: 52px;
      top: 44px;
    }
  }

  &__title {
    @include font-style(22px, normal, $font-weight-bold);
    margin-bottom: 16px;
    width: 260px;

    @include media-query(lg-and-up) {
      width: 100%;
    }

    &_auth {
      max-width: 350px;
    }
  }

  &__p {
    margin-bottom: 20px;
  }

  &__form {
    padding-top: 12px;
  }

  &__button {
    margin-top: 32px !important;
  }
}
</style>
