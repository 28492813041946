import validate from "/builds/oskelly.ru/oskelly-site-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import cart_45link_45global from "/builds/oskelly.ru/oskelly-site-frontend/middleware/cartLink.global.ts";
import concierge_45link_45global from "/builds/oskelly.ru/oskelly-site-frontend/middleware/conciergeLink.global.ts";
import main_45global from "/builds/oskelly.ru/oskelly-site-frontend/middleware/main.global.ts";
import osocial_45redirect_45global from "/builds/oskelly.ru/oskelly-site-frontend/middleware/osocialRedirect.global.ts";
import manifest_45route_45rule from "/builds/oskelly.ru/oskelly-site-frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cart_45link_45global,
  concierge_45link_45global,
  main_45global,
  osocial_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/oskelly.ru/oskelly-site-frontend/middleware/auth.ts"),
  gender: () => import("/builds/oskelly.ru/oskelly-site-frontend/middleware/gender.ts"),
  product: () => import("/builds/oskelly.ru/oskelly-site-frontend/middleware/product.ts"),
  "sale-info": () => import("/builds/oskelly.ru/oskelly-site-frontend/middleware/saleInfo.ts")
}