<template>
  <!-- TODO захардкодили баннер Выбор Oskelly -->
  <BusinessProductsBlock
    v-if="(data ?? []).length"
    class="osk-products-oskelly-choice"
    :title="$t('common.productsOskellyChoice')"
    :products="data ?? []"
    show-more-link="/catalog/bannerV2/393b89bc-8da1-45fc-ba98-62127a6fd849"
  />
</template>

<script setup lang="ts">
import { useCatalogProvider } from '~/providers/catalog';

const { fetchCatalog } = useCatalogProvider();

const { data } = useLazyAsyncData(() => fetchCatalog({ hasOurChoice: true, pageLength: 12, page: 1 })
  .then((res) => res?.items ?? []));
</script>
