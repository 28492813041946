<template>
  <UikitDialog
    :model-value="modelValue"
    position="right"
    full-height
    full-height-no-scroll
    :no-shadow="!isDesktop"
    :hide-backdrop="!isDesktop"
    no-padding
    hide-close
    content-class="osk-global-dialog osk-global-dialog_second osk-auth-login-dialog"
    @update:model-value="updateModelValue"
  >
    <UikitIcon
      name="ArrowBack"
      size="l"
      class="osk-dialog__close"
      @click="handleBack"
    />

    <a
      class="osk-global-dialog__phone"
      href="tel:+78007075308"
      @click="handlePhone"
    >
      <UikitIcon
        name="Phone"
        size="l"
      />
    </a>

    <div class="osk-global-dialog__title">
      {{ $t('auth.login.title') }}
    </div>

    <p class="osk-global-dialog__p">
      {{ $t('auth.login.subtitle') }}
    </p>

    <UikitForm
      ref="$form"
      class="osk-auth-login-dialog__form"
      @submit-validate-success="submitSuccess"
      @submit-validate-error="handleSubmitError"
    >
      <UikitFormInput
        ref="$emailInput"
        name="email"
        label="E-mail"
        rules="required|email"
        autocomplete="email"
      />

      <UikitFormInput
        name="password"
        type="password"
        :label="$t('common.auth.password')"
        rules="required"
        autocomplete="current-password"
      />

      <UikitFormErrorMessage name="globalError" />

      <UikitButton
        class="osk-auth-login-dialog__button"
        full-width
        :loading="loading"
      >
        {{ $t('common.auth.enter') }}
      </UikitButton>

      <div class="osk-auth-login-dialog__bottom">
        <span
          class="osk-auth-login-dialog__restorePassword"
          @click="restorePassword"
        >
          {{ $t('auth.restorePassword') }}
        </span>
      </div>
    </UikitForm>

    <template #dialogs>
      <AuthRestorePasswordDialog
        v-model="authRestorePasswordDialog"
        :z-index="1102"
        @global-close="globalClose"
      />
    </template>
  </UikitDialog>
</template>

<script setup lang="ts">
import { useAccountService } from '~/providers/account';

import type { TSubmitSuccess } from '~/types/components/form';
import { AnalyticsEvents } from '~/types/analytics';

interface IForm {
  email: string;
  password: string;
  globalError?: string
}

const props = defineProps<{
  modelValue: boolean
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
  (e: 'success'): void,
  (e: 'globalClose'): void,
}>();

const page = 'authorization';

const authRestorePasswordDialog = ref(false);
const $emailInput = ref();
const loading = ref(false);
const $form = ref();

const { t } = useI18n();
const { isDesktop } = useUiBreakpoints();
const route = useRoute();
const { getGlobalSettings } = useGlobalSettings(route);
const { $addEvent } = useNuxtApp();

const { auth } = useAccountService();
const { account, savedAccounts, authDialogChapter } = useAccountStore();

function restorePassword() {
  authRestorePasswordDialog.value = true;

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'forgot_password',
  });
}

function globalClose() {
  emits('update:modelValue', false);
  emits('globalClose');
}

function saveAccount() {
  const {
    nickname, email, avatarPath, id,
  } = account.value;

  if (!nickname || !email) return;
  if (!savedAccounts.value) savedAccounts.value = [];

  const isAccountSaved = savedAccounts.value?.some((item) => item.email === email);

  if (isAccountSaved) return;

  const data = {
    nickname, email, avatarPath, id: id ? String(id) : undefined,
  };

  savedAccounts.value.push(data);
}

function sendAuthorizationEvent() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'authorization',
  });
}

function handleSubmitError() {
  $form.value.setFieldError('globalError', undefined);
  sendAuthorizationEvent();
}

async function submitSuccess({ values, actions }: TSubmitSuccess<IForm>) {
  actions.setFieldError('globalError', undefined);

  loading.value = true;

  sendAuthorizationEvent();

  try {
    await auth(values.email, values.password);
    await getGlobalSettings();

    if (account.value.id) {
      saveAccount();
    }

    $addEvent(AnalyticsEvents.EnterLogin, {
      page,
      chapter: authDialogChapter.value,
    });
  } catch (error) {
    actions.setFieldError('globalError', error.data?.message ?? t('common.auth.wrongNameOrPassword'));

    loading.value = false;
    console.error(error);
  }
}

function handleBack() {
  emits('update:modelValue', false);

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'back',
  });
}

function handlePhone() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'contact_mobile',
  });
}

function updateModelValue(value: boolean) {
  emits('update:modelValue', value);
}

watch(() => props.modelValue, async (value) => {
  if (value) {
    $addEvent(AnalyticsEvents.BlockView, {
      page,
      chapter: authDialogChapter.value,
    });
  } else {
    loading.value = false;
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-auth-login-dialog {
  .osk-global-dialog__p {
    margin-bottom: 32px;
  }

  &__button {
    margin-top: 32px !important;
  }

  &__bottom {
    text-align: center;
    margin-top: 24px;
  }

  &__restorePassword {
    font-weight: 600;
    cursor: pointer;

    @include media-query(lg-and-up) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
