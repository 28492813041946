export function useWidgetCloudflareTurnstile() {
  const turnstileWidgetId = useState('turnstileWidgetId', () => null);
  const turnstileToken = useState('turnstileToken', () => null);
  const isVisibleWidgetContainer = useState('isVisibleWidgetContainer', () => true);

  function resetTurnstileWidget() {
    turnstile.reset(turnstileWidgetId.value);
  }

  function removeTurnstileWidget() {
    turnstile.remove(turnstileWidgetId.value);

    turnstileWidgetId.value = null;
    isVisibleWidgetContainer.value = false;
  }

  return {
    turnstileWidgetId,
    turnstileToken,
    isVisibleWidgetContainer,
    resetTurnstileWidget,
    removeTurnstileWidget,
  };
}
