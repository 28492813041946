<template>
  <UikitFormField
    ref="$field"
    :model-value="modelValue"
    :name="name"
    :label="label"
    :rules="rules"
    :required="required"
    :border-bottom="borderBottom"
    :no-error-message="noErrorMessage"
    :error-text="errorText"
    :icon-left="iconLeft"
    :icon-right="iconRight"
    :text-left="textLeft"
    :disabled="disabled"
    :background="background"
    :round="round"
    :hide-star="hideStar"
    :class="{
      'osk-input': true,
      'osk-input_round-disable': fieldRoundDisabled,
    }"
    @update:model-value="emits('update:modelValue', $event)"
  >
    <template
      v-if="slots.prepend"
      #prepend
    >
      <slot name="prepend" />
    </template>

    <input
      v-if="!textarea && mask"
      ref="$input"
      v-maska:[maskOptions]
      :data-maska="mask"
      :value="value"
      :type="typeComp"
      :disabled="disabled ?? false"
      :class="{
        'osk-input__input': true,
        [`osk-input__input_size-${size}`]: size,
        'osk-input__input_disabled': disabled,
        [`osk-input__input_bg-${props.background}`]: !!props.background,
      }"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :inputmode="inputmode"
      :pattern="pattern"
      v-on="validationListeners"
      @focus="emits('focus')"
      @blur="emits('blur', value)"
    >

    <input
      v-else-if="!textarea && !mask"
      ref="$input"
      :value="value"
      :type="typeComp"
      :disabled="disabled ?? false"
      :class="{
        'osk-input__input': true,
        [`osk-input__input_size-${size}`]: size,
        'osk-input__input_disabled': disabled,
        [`osk-input__input_bg-${props.background}`]: !!props.background,
        'osk-input__input_password': typeComp === 'password',
      }"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :inputmode="inputmode"
      :pattern="pattern"
      v-on="validationListeners"
      @focus="emits('focus')"
      @blur="emits('blur', value)"
    >

    <textarea
      v-else-if="textarea"
      ref="$textarea"
      :value="value"
      :disabled="disabled ?? false"
      :class="{
        'osk-input__textarea': true,
        'osk-input__textarea_disabled': disabled,
        [`osk-input__textarea_bg-${props.background}`]: !!props.background,
      }"
      :placeholder="placeholder"
      :rows="rows ?? 5"
      v-on="validationListeners"
      @focus="emits('focus')"
      @blur="emits('blur', value)"
    />

    <template
      v-if="isPassword || buttonRightIcon || clearable || loading"
      #append
    >
      <button
        v-if="isPassword"
        type="button"
        :class="{
          'osk-input__icon-button': true,
          'osk-input__icon-button_borderBottom': borderBottom,
        }"
        @click="isShowPassword = !isShowPassword"
      >
        <UikitIcon
          :name="isShowPassword ? 'EyeOff' : 'EyeOn'"
          size="l"
        />
      </button>

      <button
        v-if="clearable && value"
        class="osk-input__icon-button osk-input__icon-button_cursor-pointer"
        type="button"
        @click="handleClearable"
      >
        <UikitIcon
          name="Cross"
          size="m"
          :color="background === 'grey-4' ? 'grey' : undefined"
        />
      </button>

      <UikitLoader
        v-if="loading"
        class="osk-input__loader"
        small
      />

      <button
        v-else-if="buttonRightIcon"
        :type="buttonRightNoFocus ? 'submit' : 'button'"
        :class="{
          'osk-input__icon-button': tryOnBeforeUnmount,
          'osk-input_noFocus': buttonRightNoFocus,
          'osk-input__icon-button_cursor-pointer': !buttonRightNoFocus
        }"
        @click.stop="$emit('buttonRightIconClick')"
      >
        <UikitIcon
          :name="buttonRightIcon"
          size="m"
        />
      </button>
    </template>

    <template
      v-if="slots.message"
      #message
    >
      <slot name="message" />
    </template>
  </UIkitFormField>
</template>

<script setup lang="ts">
import { RuleExpression } from 'vee-validate';
import type { MaskInputOptions } from 'maska';

import type { TIconNames } from '~/assets/icons';

import { FieldContext } from '~/types/components/form';

const props = defineProps<{
  modelValue?: string | number
  name: string
  type?: 'text' | 'password' | 'number' | 'tel'
  label?: string
  disabled?: boolean
  textarea?: boolean
  placeholder?: string
  rules?: RuleExpression<string | number | number[] | boolean | null>
  required?: boolean
  borderBottom?: boolean
  noErrorMessage?: boolean
  errorText?: string,
  capitalize?: boolean
  round?: boolean
  iconLeft?: TIconNames,
  iconRight?: TIconNames,
  textLeft?: string
  buttonRightIcon?: TIconNames,
  buttonRightNoFocus?: boolean,
  mask?: string,
  maskOptions?: MaskInputOptions,
  background?: 'grey-4'
  rows?: number
  autoresize?: boolean
  size?: 'm' | 'l'
  autocomplete?: string
  clearable?: boolean
  loading?: boolean
  inputmode?: 'text' | 'search' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal'
  pattern?: string
  hideStar?: boolean
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: string): void,
  (e: 'focus'): void,
  (e: 'blur', value?: string): void,
  (e: 'clear', value: string): void,
  (e: 'buttonRightIconClick'): void,
}>();

const slots = useSlots();

const $field = ref<FieldContext>();
const $textarea = ref<HTMLElement>();
const $input = ref<HTMLElement>();
const isShowPassword = ref(false);
const fieldRoundDisabled = ref(false);

const value = computed(() => $field?.value?.value as string);
const typeComp = computed(() => {
  if (isShowPassword.value) return 'text';

  return props.type ?? 'text';
});
const isPassword = computed(() => props.type === 'password');

const validationListeners = computed(() => {
  if (!$field.value) return {};

  const {
    handleChange, errorMessage, value: fieldValue,
  } = $field.value;

  const result = {
    blur: (e: Event) => handleChange(e, !!fieldValue),
    change: (e: Event) => handleChange(e, false),
    input: (e: Event) => {
      if (props.capitalize) {
        (e.target as HTMLInputElement).value = capitalizeFunc((e.target as HTMLInputElement).value);
      }

      return handleChange(e, false);
    },
  };

  if (errorMessage) {
    result.input = handleChange;
  }

  return result;
});
const textareaHeight = computed(() => $textarea.value?.offsetHeight);

function capitalizeFunc(str?: string) {
  if (!str) return '';

  const capitalizedFirst = str[0].toUpperCase();
  const rest = str.slice(1);

  return capitalizedFirst + rest;
}

function resizeTextarea() {
  const element = $textarea.value;

  if (!element) return;

  element.style.height = 'auto';

  const offset = element.offsetHeight - element.clientHeight;
  const height = element.scrollHeight + offset;

  element.style.height = height >= 88 ? '88px' : `${height}px`;

  if (props.round) {
    fieldRoundDisabled.value = element.offsetHeight > (textareaHeight.value ?? 0);
  }
}

async function addAutoResize() {
  if (!props.autoresize) return;

  await nextTick();

  const element = $textarea.value;

  if (!element) return;

  element.style.boxSizing = 'border-box';
  element.addEventListener('input', resizeTextarea);
}

function setFocus() {
  const element = $textarea.value || $input.value;

  if (element) {
    element.focus();
  }
}

function setBlur() {
  const element = $textarea.value || $input.value;

  if (element) {
    element.blur();
  }
}

function handleClearable() {
  if (!$field.value || props.disabled) return;

  emits('clear', value.value);

  const { setValue } = $field.value;

  setValue('');
}

watch(() => props.autoresize, () => {
  if (props.autoresize && props.textarea) {
    addAutoResize();
  }
}, { immediate: true });

watch(() => props.modelValue, () => {
  const element = $textarea.value;

  if (element && props.autoresize && !props.modelValue) {
    element.style.height = 'auto';

    if (props.round) {
      fieldRoundDisabled.value = false;
    }
  }
});

defineExpose({
  setFocus,
  setBlur,
  $input,
});

onUnmounted(() => {
  const element = $textarea.value;

  if (element && props.autoresize) {
    element.removeEventListener('input', resizeTextarea);
  }
});
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-input {
  &_round-disable {
    border-radius: 20px;
  }

  &__input {
    -moz-appearance: textfield;
    background: inherit;
  }

  &__input,
  &__textarea {
    outline: 0;
    border: 0;
    width: 100%;
    font-size: $font-size-body;

    &_disabled {
      background-color: transparent;
      pointer-events: none;
    }

    &::placeholder {
      color: var(--grey-2);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:autofill,
    &:autofill:hover,
    &:autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__input {
    height: 40px;
    line-height: 40px;

    &_withIconRight {
      padding-right: $indent-mlarge;
    }

    &_bg-grey-4 {
      background: $grey-4;
    }

    &_size-m {
      height: 34px;
      line-height: 34px;
    }

    &_size-l {
      height: 44px;
      line-height: 44px;
    }

    &_password {
      font-family: 'pass', $font-family-default;
      font-size: $font-size-title;
      letter-spacing: 1px;

      &::placeholder {
        font-size: $font-size-body;
        letter-spacing: 0;
      }
    }
  }

  &__textarea {
    height: auto;
    padding: $indent-compact 0;
    resize: none;
    line-height: 19px;

    &::-webkit-scrollbar {
      display: none;
    }

    &_bg-grey-4 {
      background: $grey-4;
    }
  }

  &__icon-button {
    padding-left: $indent-compact;
    padding-right: $indent-compact;
    position: relative;
    cursor: default;

    & + & {
      margin-left: -16px;
      z-index: 1;
    }

    &_borderBottom {
      padding-right: 0;
    }

    &_cursor-pointer {
      cursor: pointer;
    }
  }

  &__loader {
    margin: auto;
    margin-right: 12px;
    margin-left: 12px;
  }
}
</style>
