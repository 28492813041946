import { useApi } from '~/restAPI';
import { Catalog } from '~/restAPI/Catalog';

export default defineNuxtRouteMiddleware(async ({ query, meta }) => {
  const { createApiInstance } = useApi();
  const catalogApi = createApiInstance(Catalog);

  try {
    const productId = await catalogApi.getProductIdBySaleInfoIdUsingGet({
      saleInfoId: query.saleInfoId as string,
    }).then((data) => data.data.data);

    // eslint-disable-next-line no-param-reassign
    meta.saleInfoId = query.saleInfoId;
    // eslint-disable-next-line no-param-reassign
    meta.productId = productId;
  } catch (e) {
    console.error(`Ошибка при получении productId по saleInfoId = ${query.saleInfoId}`, (e as Error).message);
  }
});
