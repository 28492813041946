<template>
  <UikitDialog
    v-model="model"
    position="right"
    full-height
    no-padding
    dark
    class="osk-boutiques-map-dialog"
  >
    <div class="osk-dialog-wrapper">
      <BusinessBoutiquesMap
        :boutiques="boutiques"
        @boutique-click="emits('boutiqueClick', $event)"
      />
    </div>
  </UikitDialog>
</template>

<script setup lang="ts">
import type { IBoutique } from '@/composables/useBoutiques';

const model = defineModel<boolean>({ required: true });

defineProps<{
  boutiques?: IBoutique[] | null
}>();

const emits = defineEmits<{
  boutiqueClick: [boutique: IBoutique]
}>();
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-boutiques-map-dialog {
  z-index: 1011 !important;

  .osk-dialog-wrapper {
    padding: 0;
  }
}
</style>
