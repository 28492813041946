import { useApi } from '~/restAPI';
import { Bonuses } from '~/restAPI/Bonuses';

export function usePoints() {
  const { createApiInstance } = useApi();

  const bonusesApi = createApiInstance(Bonuses);

  const {
    data: pointsBalance,
    execute: getBalanceBriefRequest,
    pending: getBalanceBriefLoading,
    error: getBalanceBriefError,
  } = useLazyAsyncData(() => bonusesApi.getBalanceBrief().then((res) => res?.data?.data), {
    immediate: false,
  });

  getBalanceBriefLoading.value = false;

  const {
    data: pointsHistory,
    execute: getHistoryRequest,
    pending: getHistoryLoading,
    error: getHistoryError,
  } = useLazyAsyncData(() => bonusesApi.getHistory({
    pageNumber: 1,
    pageSize: 4,
  }).then((res) => res?.data?.data), {
    immediate: false,
  });

  getHistoryLoading.value = false;

  const userPointsComp = computed(() => pointsBalance.value?.amount?.total);
  const burningPointsComp = computed(() => pointsBalance.value?.burningAmount);

  function getBalanceBrief() {
    return getBalanceBriefRequest();
  }

  function getHistory() {
    return getHistoryRequest();
  }

  return {
    pointsBalance,
    getBalanceBrief,
    getBalanceBriefError,
    getBalanceBriefLoading,
    userPointsComp,
    burningPointsComp,
    pointsHistory,
    getHistory,
    getHistoryLoading,
  };
}
