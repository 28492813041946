<template>
  <div class="osk-boutiques-map">
    <UikitLoaderInner
      v-if="!VueYandexMaps.isLoaded.value || !boutiques"
    />

    <yandex-map
      :settings="{
        location: LOCATION,
        mode: 'vector',
        showScaleInCopyrights: true,
      }"
    >
      <yandex-map-default-scheme-layer :settings="{ customization }" />
      <yandex-map-default-features-layer />

      <yandex-map-marker
        v-for="(boutique, index) in boutiques"
        :key="index"
        position="top left"
        :settings="{ coordinates: boutique.coordinates }"
      >
        <img
          alt="boutique"
          :class="{
            'osk-boutiques-map__pin': true,
            'osk-boutiques-map__pin_active': activeSlideIndex === index,
          }"
          src="~assets/img/oskelly-logo-mini.svg"
          @click="openBoutiqueDialog(boutique)"
          @mouseenter="handleMapPinEnter(index)"
        >
      </yandex-map-marker>
    </yandex-map>

    <UikitSwiper
      v-if="boutiques?.length"
      ref="$swiper"
      off
      :css-mode="!isDesktop"
      :breakpoints="sliderBreakpoints"
      class="osk-boutiques-map__items"
    >
      <UikitSwiperSlide
        v-for="(boutique, index) in boutiques"
        :key="index"
        class="osk-boutiques-map__item"
        @click="handleBoutiqueClick(index)"
      >
        <img
          alt="boutique"
          class="osk-boutiques-map__boutique-image"
          :src="boutique.images[0]"
        >

        <div class="osk-boutiques-map__boutique-content">
          <div class="osk-boutiques-map__boutique-title">
            {{ boutique.title }}
          </div>

          <div class="osk-boutiques-map__boutique-lots">
            <span
              v-for="(lot, idx) in boutique.lots"
              :key="idx"
              class="osk-boutiques-map__boutique-lot"
            >
              {{ lot }}
            </span>
          </div>

          <div
            class="osk-boutiques-map__boutique-link"
            @click.stop="openBoutiqueDialog(boutique)"
          >
            {{ $t('common.moreDetails') }}
          </div>
        </div>
      </UikitSwiperSlide>
    </UikitSwiper>
  </div>
</template>

<script setup lang="ts">
import {
  VueYandexMaps,
  YandexMap,
  YandexMapDefaultFeaturesLayer,
  YandexMapDefaultSchemeLayer,
  YandexMapMarker,
} from 'vue-yandex-maps';
import type { YMapLocationRequest } from '@yandex/ymaps3-types';

import UikitSwiper from '~/components/uikit/swiper/index.vue';

import { customization } from './mapOptions';

defineProps<{
  boutiques?: IBoutique[] | null
}>();

const emits = defineEmits<{
  boutiqueClick: [boutique: IBoutique]
}>();

const LOCATION: YMapLocationRequest = {
  center: [ 37.617936, 55.761978 ],
  zoom: 15,
};

const sliderBreakpoints = {
  0: {
    slidesPerView: 'auto' as const,
    centeredSlides: true,
    slidesPerGroup: 1,
    spaceBetween: 16,
  },
};

const $swiper = ref<ComponentPublicInstance<typeof UikitSwiper>>();

const { isDesktop } = useUiBreakpoints();

const activeSlideIndex = computed(() => $swiper.value?.swiper?.activeIndex ?? 0);

function handleBoutiqueClick(index: number) {
  if (activeSlideIndex.value === index) return;

  $swiper.value?.swiper.slideTo(index);
}

function handleMapPinEnter(index: number) {
  $swiper.value?.swiper.slideTo(index);
}

function openBoutiqueDialog(boutique: IBoutique) {
  emits('boutiqueClick', boutique);
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-boutiques-map {
  height: 100%;
  position: relative;

  &__pin {
    cursor: pointer;
    max-width: unset;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: all linear .2s;

    &_active {
      width: 40px;
      height: 40px;
    }
  }

  .osk-swiper {
    &.osk-boutiques-map__items {
      position: absolute;
      bottom: 52px;
      left: 0;
      width: 100%;
      user-select: none;
    }
  }

  .swiper-wrapper {
    width: auto;
    max-width: 100%;

    .swiper-slide {
      &.osk-boutiques-map__item {
        background: var(--black);
        color: var(--white);
        padding: var(--indent-medium);
        width: 311px;
        border-radius: $radius-small;
        flex-shrink: 0;
        display: flex;
        align-items: flex-start;
        cursor: pointer;

        @include media-query(lg-and-up) {
          width: 388px;
        }
      }
    }
  }

  &__boutique-image {
    width: 108px;
    height: 108px;
    border-radius: $radius-small;
    margin-right: var(--indent-medium);
    pointer-events: none;
    object-fit: cover;
  }

  &__boutique-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__boutique-title {
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--indent-small);
  }

  &__boutique-lots {
    margin-bottom: var(--indent-small);
  }

  &__boutique-lot {
    @include font-style(var(--font-size-caption), var(--line-height-caption), var(--font-weight-medium));
    color: var(--grey-3);

    & + &:before {
      content: '•';
      margin: 0 4px;
    }
  }

  &__boutique-link {
    @include font-style(var(--font-size-caption), var(--line-height-caption), var(--font-weight-semibold));
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
